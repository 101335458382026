import {
    WHATS_APP_CONTACNTS_OPERATION_START,
    WHATS_APP_CONTACNTS_OPERATION_FAIL,
    WHATS_APP_CONTACNTS_OPERATION_FINISH,
    WHATS_APP_CONTACNTS_SUCCESS
} from '../../../actions/types';

const INITIAL_STATE = {
    error: '',
    loading: false,
    contacts: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case WHATS_APP_CONTACNTS_OPERATION_START:
            return { ...state, loading: true, error: '' };
        case WHATS_APP_CONTACNTS_OPERATION_FINISH:
            return { ...state, loading: false };
        case WHATS_APP_CONTACNTS_OPERATION_FAIL:
            return { ...state, error: { [action.payload.props]: action.payload.value } };
        case WHATS_APP_CONTACNTS_SUCCESS:
            return { ...state, contacts: action.payload };
        default:
            return state;
    }
};

