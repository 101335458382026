import {
    SIGNUP_INFO_CHANGED,
    SIGNUP_OPERATION_START,
    SIGNUP_OPERATION_FAIL,
    SIGNUP_OPERATION_FINISH,
    SIGNUP_SUCCESS
} from '../../actions/types';

const INITIAL_STATE = {
    error: '',
    loading: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SIGNUP_INFO_CHANGED:
            return { ...state, [action.payload.props]: action.payload.value };
        case SIGNUP_OPERATION_START:
            return { ...state, loading: true };
        case SIGNUP_OPERATION_FINISH:
            return { ...state, loading: false };
        case SIGNUP_OPERATION_FAIL:
            return { ...state, error: { [action.payload.props]: action.payload.value } };
        case SIGNUP_SUCCESS:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};
