import i18n from 'i18next';
import arMessages from '../locates/devExtremeDictionaryAr.json';
import enMessages from 'devextreme/localization/messages/en.json';
import config from 'devextreme/core/config';
import { locale, loadMessages, } from "devextreme/localization";
import cookies from 'react-cookies';


export default (newLang, setNewLang) => {
    const cookiesLang = cookies.load('languagePref');
    // if (lang === newLang) return;
    document.querySelector("#loader").style.display = "block";
    document.querySelector("#root").style.visibility = "hidden";
    i18n.changeLanguage(newLang);
    if (newLang === 'ar') {
        config({ rtlEnabled: true });
        loadMessages(arMessages);
        locale(newLang);
        document.getElementById('linkcss2').href = `${process.env.PUBLIC_URL}/css/rtl.css`;
        document.getElementById('linkcss').href = "https://cdn.rtlcss.com/semantic-ui/2.4.1/semantic.rtl.min.css";
    }
    else {
        config({ rtlEnabled: false });
        loadMessages(enMessages);
        locale(newLang);
        document.getElementById('linkcss').href = "//cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css";
        document.getElementById('linkcss2').href = `${process.env.PUBLIC_URL}/css/ltr.css`;
    }
    setTimeout(() => {
        document.querySelector("#loader").style.display = "none";
        document.querySelector("#root").style.visibility = "visible";
    }, 1500);

    if (cookiesLang) cookies.remove('languagePref');
    cookies.save('languagePref', newLang, {
        path: '/',
        expires: new Date().setDate(Date.now() + 1000 * 60 * 60 * 24 * 14),
        maxAge: 60 * 60 * 24 * 365
    });
    setNewLang(newLang);
};
