
import { store } from '../store';
const { getState } = store;
const selectedBranchID = () => getState().adminBranches.selectedBranchId;

export default (selectedBranchId = selectedBranchID()) => ({
    HOME: { URL: '/', RESOURCES: [], PERMISSIONS: [] },
    RESET_PASSWORD: { URL: '/resetpassword/:resetCode', RESOURCES: [], PERMISSIONS: [] },
    ACTIVATION: { URL: '/activation', RESOURCES: [], PERMISSIONS: [] },
    SIGNIN: { URL: '/signin', RESOURCES: [], PERMISSIONS: [] },
    ADMIN_DASHBOARD: { URL: '/admin/dashboard', RESOURCES: ['ADMINS_CONTROL_PANEL', `OB_BRANCH_${selectedBranchId}_ADMINS_CONTROL_PANEL`], PERMISSIONS: [] },
    ADMIN_NEW_MESSAGE: { URL: '/admin/whatsapp-massages/form/:messageId', RESOURCES: [`OB_BRANCH_${selectedBranchId}_MESSAGES`], PERMISSIONS: ['ADD', 'UPDATE'] },
    ADMIN_MESSAGES: { URL: '/admin/whatsapp-massages', RESOURCES: [`OB_BRANCH_${selectedBranchId}_MESSAGES`], PERMISSIONS: [] },
    ADMIN_CONTACTS: { URL: '/admin/whatsapp-contacts', RESOURCES: [`OB_BRANCH_${selectedBranchId}_CONTACTS`], PERMISSIONS: [] },
    ADMIN_BRANCHES: { URL: '/admin/branches', RESOURCES: ['OB_BRANCHES', `OB_BRANCH_${selectedBranchId}`], PERMISSIONS: [] },
    ADMIN_EMPLOYEES: { URL: '/admin/admins', RESOURCES: ['USERS', `OB_BRANCH_${selectedBranchId}_USERS`], PERMISSIONS: [] },
    ADMIN_ROLES: { URL: '/admin/roles', RESOURCES: ['ACCESS_CONTROL', `OB_BRANCH_${selectedBranchId}_ACCESS_CONTROL`], PERMISSIONS: [] },
    ADMIN_CURRENCIES: { URL: '/admin/financial/currencies', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_CURRENCIES`], PERMISSIONS: [] },
    ADMIN_CURRENCIES_EQUIVALENTS: { URL: '/admin/financial/currenciesEquivalents', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_CURRENCIES`], PERMISSIONS: [] },
    ADMIN_MATERIALS: { URL: '/admin/financial/materials', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_MATERIALS`], PERMISSIONS: [] },
    ADMIN_MATERIALS_FAMILIES: { URL: '/admin/financial/materials/families', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_FAMILIES`], PERMISSIONS: [] },
    ADMIN_MATERIALS_QUALITIES: { URL: '/admin/financial/materials/qualities', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_QUALITIES`], PERMISSIONS: [] },
    ADMIN_MATERIALS_APPLICATIONS: { URL: '/admin/financial/materials/applications', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_APPLICATIONS`], PERMISSIONS: [] },
    ADMIN_MATERIALS_BRANDS: { URL: '/admin/financial/materials/brands', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_BRANDS`], PERMISSIONS: [] },
    ADMIN_SEND_MATERIAL_MESSAGE: { RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_MATERIALS_MESSAGES`], PERMISSIONS: [] },
    ADMIN_CUSTOMERS: { URL: '/admin/financial/customers', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_CUSTOMERS`], PERMISSIONS: ['READ', 'ADD'] },
    ADMIN_OFFERS: { URL: '/admin/financial/offers', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_OFFERS`], PERMISSIONS: ['READ', 'ADD'] },
    ADMIN_FINAL_OFFERS: { URL: '/admin/financial/offers/final', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_SALES_OFFERS`], PERMISSIONS: [] },
    ADMIN_STOCK_OFFERS: { URL: '/admin/financial/offers/stock', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_MATERIALS`], PERMISSIONS: [] },
    ADMIN_REQUEST_MATERIALS: { URL: '/admin/financial/offers/request', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_OFFERS_REQUESTS`], PERMISSIONS: [] },
    ADMIN_REQUESTED_MATERIALS: { URL: '/admin/financial/offers/requested', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_OFFERS_REQUESTS`], PERMISSIONS: [] },
    ADMIN_SEND_OFFER_MESSAGE: { RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_OFFERS_MESSAGES`], PERMISSIONS: [] },
    ADMIN_SHIPPING_MANAGEMENTS: { URL: '/admin/financial/shipping/managements', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_SHIPPING`], PERMISSIONS: [] },
    ADMIN_SETTINGS_GENERAL: { URL: '/admin/settings/general', RESOURCES: [`SETTINGS`], PERMISSIONS: [] },
    ADMIN_SETTINGS_BRANCH: { URL: '/admin/settings/branch', RESOURCES: [`OB_BRANCH_${selectedBranchId}_SETTINGS`], PERMISSIONS: [] },
    ADMIN_COUNTRIES: { URL: '/admin/settings/countries', RESOURCES: [`OB_BRANCH_${selectedBranchId}_COUNTRIES`], PERMISSIONS: [] },
    ADMIN_REGIONS: { URL: '/admin/settings/regions', RESOURCES: [`OB_BRANCH_${selectedBranchId}_COUNTRIES`], PERMISSIONS: [] },
    ADMIN_CATEGORIES: { URL: '/admin/categories', RESOURCES: [`OB_BRANCH_${selectedBranchId}_CATEGORIES`], PERMISSIONS: [] },
    ADMIN_MANAUAL_GUIDE: { URL: '/admin/help/manualGuide', RESOURCES: [], PERMISSIONS: [] },

    ADMIN_FIN_PERIODS: { URL: '/admin/fin/periods', RESOURCES: [`OB_BRANCH_${selectedBranchId}_ACCOUNTING`], PERMISSIONS: [] },
    ADMIN_FIN_ACCOUNTS: { URL: '/admin/fin/accounts', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_FINANCIAL_ACCOUNTS`], PERMISSIONS: [] },
    ADMIN_FIN_COST_CENTERS: { URL: '/admin/fin/costCenters', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_FINANCIAL_COSTS_CENTERS`], PERMISSIONS: [] },
    ADMIN_FIN_ENTRIES: { URL: '/admin/fin/entries/:entryId', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_FINANCIAL_ENTRIES`], PERMISSIONS: [] },
    ADMIN_FIN_ENTRY_TYPES: { URL: '/admin/fin/entry/types', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_FINANCIAL_ENTRIES_TYPES`], PERMISSIONS: [] },
    ADMIN_FIN_WAREHOUSES: { URL: '/admin/fin/warehouses', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_FINANCIAL_STORES`], PERMISSIONS: [] },
    ADMIN_FIN_WAREHOUSES_TRANSPORTATIONS: { URL: '/admin/fin/warehouses/transportations', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_TRANSPORTATION`], PERMISSIONS: [] },
    ADMIN_FIN_BILLS: { URL: '/admin/fin/bills/:billId', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_FINANCIAL_BILLS`], PERMISSIONS: [] },
    ADMIN_FIN_BILL_TYPES: { URL: '/admin/fin/bill/types', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_FINANCIAL_BILLS_TYPES`], PERMISSIONS: [] },
    ADMIN_FIN_BILLS_INDEX: { URL: '/admin/fin/bills', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_FINANCIAL_BILLS`], PERMISSIONS: [] },
    ADMIN_FIN_BILLS_NEW_FORM: { URL: '/admin/fin/bill/new', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_FINANCIAL_BILLS`], PERMISSIONS: ['ADD'] },
    ADMIN_FIN_PROFORMA_INVOICE: { URL: '/admin/fin/proformaInvoice', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_PROFORMA_INVOICE`], PERMISSIONS: [] },


    ADMIN_FIN_REPORTS_MATERIAL_INVENTORY: { URL: '/admin/fin/reports/materialInventory', RESOURCES: [`OB_BRANCH_${selectedBranchId}_ACCOUNTING`], PERMISSIONS: [] },
    ADMIN_FIN_REPORTS_MATERIAL_MOVEMENT: { URL: '/admin/fin/reports/materialMovement', RESOURCES: [`OB_BRANCH_${selectedBranchId}_ACCOUNTING`], PERMISSIONS: [] },
    ADMIN_FIN_REPORTS_LEDGER: { URL: '/admin/fin/reports/ledger', RESOURCES: [`OB_BRANCH_${selectedBranchId}_ACCOUNTING`], PERMISSIONS: [] },
    ADMIN_FIN_REPORTS_TRIAL_BALANCE: { URL: '/admin/fin/reports/trialBalance', RESOURCES: [`OB_BRANCH_${selectedBranchId}_ACCOUNTING`], PERMISSIONS: [] },
    ADMIN_FIN_REPORTS_PROFITS_AND_LOSSES: { URL: '/admin/fin/reports/profitsAndLosses', RESOURCES: [`OB_BRANCH_${selectedBranchId}_ACCOUNTING`], PERMISSIONS: [] },

    ADMIN_FIN_SALES_CUSTOMERS: { URL: '/admin/financial/sales/customers', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_CUSTOMERS`], PERMISSIONS: ['READ', 'ADD'] },
    ADMIN_FIN_SALES_DEALS: { URL: '/admin/financial/sales/deals', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_CUSTOMERS`], PERMISSIONS: ['READ', 'ADD'] },
    ADMIN_FIN_SALES_CONTACTS: { URL: '/admin/financial/sales/contacts', RESOURCES: [`OB_BRANCH_${selectedBranchId}_CONTACTS`], PERMISSIONS: [] },
    ADMIN_FIN_SALES_RESERVATIONS: { URL: '/admin/financial/sales/reservations', RESOURCES: [`OB_BRANCH_${selectedBranchId}_AW_CUSTOMERS`], PERMISSIONS: [] },

});