import i18n from 'i18next';
import {
    SIGNUP_INFO_CHANGED,
    SIGNUP_OPERATION_START,
    SIGNUP_OPERATION_FAIL,
    SIGNUP_OPERATION_FINISH,
    SIGNUP_SUCCESS,
} from '../types';
import request from '../../api';
import { badReq, noConnection } from '../../helpers/errors';
import history from '../../history';
import getPaths from '../../Routers/PATHS';
const PATHS = getPaths();

export const signupInfoChanged = ({ props, value }) => {
    return { type: SIGNUP_INFO_CHANGED, payload: { props, value } };
};

export const signup = ({ firstName, lastName, country, mobile, email, password }) => async dispatch => {
    dispatch({ type: SIGNUP_OPERATION_START });
    try {
        await request.post('/auth/signup', { firstName, lastName, email, contactNumber: mobile, countryId: country, password });
        dispatch({ type: SIGNUP_SUCCESS });
        history.push(PATHS.ACTIVATION.URL)
    } catch (err) {
        if (err.response.status === 409) {
            return dispatch({ type: SIGNUP_OPERATION_FAIL, payload: { props: 'email', value: i18n.t('ErrorEmailExist') } });
        }
        if (err.response) badReq();
        else noConnection();
    } finally {
        dispatch({ type: SIGNUP_OPERATION_FINISH });
    }
};
