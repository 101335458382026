import React from 'react';
import { Header, Icon } from 'semantic-ui-react';
import history from '../../history';

const PageHeader = ({ title }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Icon
                name='arrow left'
                color='grey'
                size='large'
                style={{ cursor: 'pointer', margin: '0 1rem' }}
                onClick={() => history.goBack()}
            />
            <Header as='h1' style={{ margin: 0 }}>{title}</Header>
        </div>
    );
};

export default PageHeader;