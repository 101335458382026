import i18n from 'i18next';
import {
    POST_ACTIVATION_CODE_START,
    POST_ACTIVATION_CODE_FAIL,
    POST_ACTIVATION_CODE,
    ACTIVATION_CODE_INFO_CHANGED
} from '../types';
import { badReq, noConnection } from '../../helpers/errors';
import request from '../../api';
import history from '../../history';
import getPaths from '../../Routers/PATHS';
const PATHS = getPaths();


export const activationInfoChanged = ({ props, value }) => {
    return { type: ACTIVATION_CODE_INFO_CHANGED, payload: { props, value } };
};

export const postActivationCode = (code) => async dispatch => {
    dispatch({ type: POST_ACTIVATION_CODE_START });
    try {
        await request.post('/auth/account/activate', { activateCode: code });
        dispatch({ type: POST_ACTIVATION_CODE });
        history.push(PATHS.SIGNIN.URL);
    } catch (err) {
        dispatch({ type: POST_ACTIVATION_CODE_FAIL, payload: i18n.t('ErrorBody') });
        if (err.response) return badReq();
        else noConnection();
    }
};

export const reSendActivationCode = (userName, callback) => async dispatch => {
    dispatch({ type: POST_ACTIVATION_CODE_START });
    try {
        await request.post('/auth/account/activate/code/resend', { userName });
        dispatch({ type: POST_ACTIVATION_CODE });
        alert(i18n.t('CheckEmail'));
        callback(false);
    } catch (err) {
        dispatch({ type: POST_ACTIVATION_CODE_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        if (err.response) return badReq();
        else noConnection();
    }
};