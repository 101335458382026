import i18n from 'i18next';
import {
    WHATS_APP_CONTACNTS_OPERATION_FAIL,
    WHATS_APP_CONTACNTS_OPERATION_FINISH,
    WHATS_APP_CONTACNTS_OPERATION_START,
    WHATS_APP_CONTACNTS_SUCCESS
} from '../types';
import request from '../../api';

export const adminGetAllWhatsAppContacts = () => async (dispatch, getState) => {
    dispatch({ type: WHATS_APP_CONTACNTS_OPERATION_START });
    const { selectedBranchId } = getState().adminBranches;
    try {
        const { data: { data } } = await request.get(`/admin/contacts/all/branch/${selectedBranchId}`);
        dispatch({ type: WHATS_APP_CONTACNTS_SUCCESS, payload: data });
    } catch (err) {
        if (err.response) dispatch({ type: WHATS_APP_CONTACNTS_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: WHATS_APP_CONTACNTS_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: WHATS_APP_CONTACNTS_OPERATION_FINISH });
    }
};

export const adminUpdateWhatsAppContact = ({ idContact, coverImgUrl, rank, categoryId, specialIntroAr, email, zipCode }) => async dispatch => {
    try {
        await request.post('/admin/contact/update', { contactId: idContact, coverImgUrl, rank, categoryId, specialIntroAr, email, zipCode });
    } catch (err) {
        if (err.response) dispatch({ type: WHATS_APP_CONTACNTS_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: WHATS_APP_CONTACNTS_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    }
};

export const adminSyncWhatsAppContacts = () => async (dispatch, getState) => {
    dispatch({ type: WHATS_APP_CONTACNTS_OPERATION_START });
    const { selectedBranchId } = getState().adminBranches;
    try {
        await request.post('/admin/whatsapp/contacts/sync/obbranch', { OBBranchId: selectedBranchId });
        adminGetAllWhatsAppContacts()(dispatch, getState);
    } catch (err) {
        if (err.response) dispatch({ type: WHATS_APP_CONTACNTS_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: WHATS_APP_CONTACNTS_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: WHATS_APP_CONTACNTS_OPERATION_FINISH });
    }
};

