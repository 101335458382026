import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { FilterBuilder } from 'devextreme-react/ui/filter-builder';
import { regionsDataLookup, countriesDataLookup, citiesDataLookup } from '../../Data Stores';


const FilterBuilderView = ({ t, data }) => {
    const [filter, setFilter] = useState(data?.value || null);
    const [regions, setRegions] = useState([]);
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);

    const filterOperations = ['=', '<>'];

    const onFilterChanged = (e) => {
        if (data.setValue)
            data.setValue(e.component.getFilterExpression());
        setFilter(e.value);
    };

    const getRegions = async () => {
        const { data } = await regionsDataLookup.load();
        setRegions(data);
    };

    const getCountries = async () => {
        const { data } = await countriesDataLookup.load();
        setCountries(data);
    };

    const getCities = async () => {
        const { data } = await citiesDataLookup.load();
        setCities(data);
    };

    const getFilteredLookUp = (store) => {
        return {
            store,
            paginate: true,
            pageSize: 50,
        };
    };

    const clearFilter = () => {
        if (data.setValue) data.setValue(null);
        setFilter(null);
    };

    useEffect(() => {
        getRegions();
        getCountries();
        getCities();
    }, []);

    const fields = [
        {
            caption: t('Region'),
            dataField: 'regionId',
            filterOperations,
            lookup: {
                dataSource: () => getFilteredLookUp(regions),
                valueExpr: 'regionId',
                displayExpr: 'name'
            }
        },
        {
            caption: t('Country'),
            dataField: 'countryId',
            filterOperations,
            lookup: {
                dataSource: () => getFilteredLookUp(countries),
                valueExpr: 'id',
                displayExpr: 'name'
            }
        },
        {
            caption: t('City'),
            dataField: 'cityId',
            filterOperations,
            lookup: {
                dataSource: () => getFilteredLookUp(cities),
                valueExpr: 'idCity',
                displayExpr: 'name'
            }
        },
    ];

    return (
        <>
            <FilterBuilder
                value={filter}
                onValueChanged={(e) => onFilterChanged(e)}
                fields={fields}
                groupOperations={['and', 'or']}
            />

            {filter && <Button size='mini' color='red' basic icon='times' content={t('Clear')} onClick={clearFilter} />}
        </>
    );
};

export default withTranslation()(FilterBuilderView);