import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import getPaths from '../Routers/PATHS';
import checkResources from '../helpers/checkResources';

export const GetSideBarData = (isAdmin, portal) => {
    const { t } = useTranslation();
    const PATHS = getPaths();
    const administrativeResources = [...PATHS.ADMIN_ROLES.RESOURCES, ...PATHS.ADMIN_BRANCHES.RESOURCES, ...PATHS.ADMIN_EMPLOYEES.RESOURCES];
    const whatsappResources = [...PATHS.ADMIN_CONTACTS.RESOURCES, ...PATHS.ADMIN_MESSAGES.RESOURCES];
    const accountingsResources = [...PATHS.ADMIN_FIN_PERIODS.RESOURCES, ...PATHS.ADMIN_FIN_ACCOUNTS.RESOURCES, ...PATHS.ADMIN_FIN_COST_CENTERS.RESOURCES, ...PATHS.ADMIN_FIN_ENTRY_TYPES.RESOURCES, ...PATHS.ADMIN_FIN_ENTRIES.RESOURCES, ...PATHS.ADMIN_FIN_BILL_TYPES.RESOURCES, ...PATHS.ADMIN_FIN_BILLS.RESOURCES, ...PATHS.ADMIN_FIN_PROFORMA_INVOICE.RESOURCES];
    const reportsResources = [...PATHS.ADMIN_FIN_REPORTS_MATERIAL_MOVEMENT.RESOURCES, ...PATHS.ADMIN_FIN_REPORTS_MATERIAL_INVENTORY.RESOURCES, ...PATHS.ADMIN_FIN_REPORTS_LEDGER.RESOURCES, ...PATHS.ADMIN_FIN_REPORTS_TRIAL_BALANCE.PERMISSIONS, ...PATHS.ADMIN_FIN_REPORTS_PROFITS_AND_LOSSES.RESOURCES];
    const warehousesResources = [...PATHS.ADMIN_CUSTOMERS.RESOURCES, ...PATHS.ADMIN_MATERIALS.RESOURCES, ...PATHS.ADMIN_FIN_WAREHOUSES.RESOURCES, ...PATHS.ADMIN_FIN_WAREHOUSES_TRANSPORTATIONS.RESOURCES];
    const offersResources = [...PATHS.ADMIN_OFFERS.RESOURCES, ...PATHS.ADMIN_FINAL_OFFERS.RESOURCES, ...PATHS.ADMIN_STOCK_OFFERS.RESOURCES];
    const utilitiesResources = [...PATHS.ADMIN_CURRENCIES.RESOURCES, ...PATHS.ADMIN_SHIPPING_MANAGEMENTS.RESOURCES, ...PATHS.ADMIN_CATEGORIES.RESOURCES, ...PATHS.ADMIN_REGIONS.RESOURCES];
    const salesResources = [...PATHS.ADMIN_CUSTOMERS.RESOURCES, ...PATHS.ADMIN_FIN_SALES_CONTACTS.RESOURCES,];
    const settingsResources = [...PATHS.ADMIN_SETTINGS_GENERAL.RESOURCES, ...PATHS.ADMIN_SETTINGS_BRANCH.RESOURCES];

    if (isAdmin) return [
        {
            permission: checkResources(administrativeResources), portals: ['HR'], text: t('Administrative'), icon: 'chess king', subs: [
                { key: 1, as: Link, value: 1, permission: checkResources(PATHS.ADMIN_ROLES.RESOURCES), text: t('Roles'), icon: 'key', to: PATHS.ADMIN_ROLES.URL },
                { key: 2, as: Link, value: 2, permission: checkResources(PATHS.ADMIN_BRANCHES.RESOURCES), text: t('Branches'), icon: 'building', to: PATHS.ADMIN_BRANCHES.URL },
                { key: 3, as: Link, value: 3, permission: checkResources(PATHS.ADMIN_EMPLOYEES.RESOURCES), text: t('Employees'), icon: 'spy', to: PATHS.ADMIN_EMPLOYEES.URL },
            ]
        },
        {
            permission: checkResources(whatsappResources), portals: ['MARKETING'], text: t('WhatsApp'), icon: 'whatsapp', subs: [
                { key: 1, as: Link, value: 1, permission: checkResources(PATHS.ADMIN_CONTACTS.RESOURCES), text: t('Contacts'), icon: 'address book', to: PATHS.ADMIN_CONTACTS.URL },
                { key: 2, as: Link, value: 2, permission: checkResources(PATHS.ADMIN_MESSAGES.RESOURCES), text: t('MessagesReminder'), icon: 'envelope', to: PATHS.ADMIN_MESSAGES.URL },
            ]
        },
        {
            permission: checkResources(offersResources), portals: ['MARKETING'], text: t('Offers'), icon: 'suitcase', subs: [
                { key: 1, as: Link, value: 1, permission: checkResources(PATHS.ADMIN_MATERIALS.RESOURCES), text: t('Materials'), icon: 'sitemap', to: PATHS.ADMIN_MATERIALS.URL },
                { key: 2, as: Link, value: 2, permission: (checkResources(PATHS.ADMIN_CUSTOMERS.RESOURCES) || checkResources(PATHS.ADMIN_CUSTOMERS.RESOURCES, 'ADD')), text: `${t('Customers')} / ${t('Suppliers')}`, icon: 'users', to: PATHS.ADMIN_CUSTOMERS.URL },
                { key: 3, as: Link, value: 3, permission: (checkResources(PATHS.ADMIN_OFFERS.RESOURCES) || checkResources(PATHS.ADMIN_OFFERS.RESOURCES, 'ADD')), text: t('OffersManagement'), icon: 'pallet', to: PATHS.ADMIN_OFFERS.URL },
                { key: 4, as: Link, value: 4, permission: checkResources(PATHS.ADMIN_FINAL_OFFERS.RESOURCES), text: t('OffersSales'), icon: 'dolly flatbed', to: PATHS.ADMIN_FINAL_OFFERS.URL },
                { key: 5, as: Link, value: 5, permission: checkResources(PATHS.ADMIN_STOCK_OFFERS.RESOURCES), text: t('OffersStock'), icon: 'dolly', to: PATHS.ADMIN_STOCK_OFFERS.URL },
            ]
        },
        {
            permission: checkResources(accountingsResources), portals: ['FINANCIAL'], text: t('Accounting'), icon: 'dollar', subs: [
                { key: 1, as: Link, value: 1, permission: checkResources(PATHS.ADMIN_FIN_PERIODS.RESOURCES), text: t('Periods'), icon: 'calendar alternate', to: PATHS.ADMIN_FIN_PERIODS.URL },
                { key: 2, as: Link, value: 2, permission: checkResources(PATHS.ADMIN_FIN_ACCOUNTS.RESOURCES), text: t('Accounts'), icon: 'ticket alternate', to: PATHS.ADMIN_FIN_ACCOUNTS.URL },
                { key: 3, as: Link, value: 3, permission: checkResources(PATHS.ADMIN_FIN_COST_CENTERS.RESOURCES), text: t('CostCenters'), icon: 'boxes', to: PATHS.ADMIN_FIN_COST_CENTERS.URL },
                { key: 4, as: Link, value: 4, permission: checkResources(PATHS.ADMIN_FIN_ENTRY_TYPES.RESOURCES), text: t('EntryTypes'), icon: 'copy outline', to: PATHS.ADMIN_FIN_ENTRY_TYPES.URL },
                { key: 5, as: Link, value: 5, permission: checkResources(PATHS.ADMIN_FIN_ENTRIES.RESOURCES), text: t('Entries'), icon: 'copy', to: PATHS.ADMIN_FIN_ENTRIES.URL },
                { key: 6, as: Link, value: 6, permission: checkResources(PATHS.ADMIN_FIN_BILL_TYPES.RESOURCES), text: t('BillTypes'), icon: 'object group outline', to: PATHS.ADMIN_FIN_BILL_TYPES.URL },
                { key: 7, as: Link, value: 7, permission: checkResources(PATHS.ADMIN_FIN_BILLS.RESOURCES), text: t('Bills'), icon: 'object group', to: PATHS.ADMIN_FIN_BILLS_INDEX.URL },
                { key: 8, as: Link, value: 8, permission: checkResources(PATHS.ADMIN_FIN_PROFORMA_INVOICE.RESOURCES), text: t('ProformaInvoice'), icon: 'file alternate', to: PATHS.ADMIN_FIN_PROFORMA_INVOICE.URL },

            ]
        },
        {
            permission: checkResources(warehousesResources), portals: ['FINANCIAL'], text: t('Warehouses'), icon: 'warehouse', subs: [
                { key: 1, as: Link, value: 1, permission: checkResources(PATHS.ADMIN_FIN_WAREHOUSES.RESOURCES), text: t('Warehouses'), icon: 'cubes', to: PATHS.ADMIN_FIN_WAREHOUSES.URL },
                { key: 2, as: Link, value: 2, permission: checkResources(PATHS.ADMIN_FIN_WAREHOUSES_TRANSPORTATIONS.RESOURCES), text: t('Transportations'), icon: 'shipping fast', to: PATHS.ADMIN_FIN_WAREHOUSES_TRANSPORTATIONS.URL },
                { key: 3, as: Link, value: 3, permission: checkResources(PATHS.ADMIN_MATERIALS.RESOURCES), text: t('Materials'), icon: 'sitemap', to: PATHS.ADMIN_MATERIALS.URL },
                { key: 4, as: Link, value: 4, permission: (checkResources(PATHS.ADMIN_CUSTOMERS.RESOURCES) || checkResources(PATHS.ADMIN_CUSTOMERS.RESOURCES, 'ADD')), text: `${t('Customers')} / ${t('Suppliers')}`, icon: 'users', to: PATHS.ADMIN_CUSTOMERS.URL },
            ]
        },
        {
            permission: checkResources(reportsResources), portals: ['FINANCIAL'], text: t('Reports'), icon: 'pie chart', subs: [
                { key: 1, as: Link, value: 1, permission: checkResources(PATHS.ADMIN_FIN_REPORTS_MATERIAL_INVENTORY.RESOURCES), text: t('MaterialsInventory'), icon: 'ordered list', to: PATHS.ADMIN_FIN_REPORTS_MATERIAL_INVENTORY.URL },
                { key: 2, as: Link, value: 2, permission: checkResources(PATHS.ADMIN_FIN_REPORTS_MATERIAL_MOVEMENT.RESOURCES), text: t('MaterialMovement'), icon: 'exchange', to: PATHS.ADMIN_FIN_REPORTS_MATERIAL_MOVEMENT.URL },
                { key: 3, as: Link, value: 3, permission: checkResources(PATHS.ADMIN_FIN_REPORTS_LEDGER.RESOURCES), text: t('Ledger'), icon: 'book', to: PATHS.ADMIN_FIN_REPORTS_LEDGER.URL },
                { key: 4, as: Link, value: 4, permission: checkResources(PATHS.ADMIN_FIN_REPORTS_TRIAL_BALANCE.RESOURCES), text: t('TrialBalance'), icon: 'balance', to: PATHS.ADMIN_FIN_REPORTS_TRIAL_BALANCE.URL },
                { key: 5, as: Link, value: 5, permission: checkResources(PATHS.ADMIN_FIN_REPORTS_PROFITS_AND_LOSSES.RESOURCES), text: t('FinalAccountsReports'), icon: 'chart line', to: PATHS.ADMIN_FIN_REPORTS_PROFITS_AND_LOSSES.URL },

            ]
        },
        {
            permission: checkResources(salesResources), portals: ['SALES'], text: t('Management'), icon: 'desktop', subs: [
                { key: 1, as: Link, value: 1, permission: checkResources(PATHS.ADMIN_FIN_SALES_CONTACTS.RESOURCES), text: t('Contacts'), icon: 'address book', to: PATHS.ADMIN_FIN_SALES_CONTACTS.URL },
                { key: 2, as: Link, value: 2, permission: (checkResources(PATHS.ADMIN_FIN_SALES_CUSTOMERS.RESOURCES) || checkResources(PATHS.ADMIN_FIN_SALES_CUSTOMERS.RESOURCES, 'ADD')), text: `${t('Customers')}`, icon: 'users', to: PATHS.ADMIN_FIN_SALES_CUSTOMERS.URL },
                { key: 3, as: Link, value: 3, permission: checkResources(PATHS.ADMIN_FIN_SALES_DEALS.RESOURCES), text: t('Deals'), icon: 'handshake', to: PATHS.ADMIN_FIN_SALES_DEALS.URL },
                { key: 4, as: Link, value: 4, permission: checkResources(PATHS.ADMIN_FIN_SALES_RESERVATIONS.RESOURCES), text: t('Reservations'), icon: 'hand paper', to: PATHS.ADMIN_FIN_SALES_RESERVATIONS.URL },

            ]
        },
        {
            permission: checkResources(utilitiesResources), portals: ['GENERAL'], text: t('Utilities'), icon: 'configure', subs: [
                { key: 1, as: Link, value: 1, permission: checkResources(PATHS.ADMIN_REGIONS.RESOURCES), text: t('Regions'), icon: 'globe', to: PATHS.ADMIN_REGIONS.URL },
                { key: 2, as: Link, value: 2, permission: checkResources(PATHS.ADMIN_CATEGORIES.RESOURCES), text: t('Categories'), icon: 'th', to: PATHS.ADMIN_CATEGORIES.URL },
                { key: 3, as: Link, value: 3, permission: checkResources(PATHS.ADMIN_CURRENCIES.RESOURCES), text: t('Currencies'), icon: 'currency', to: PATHS.ADMIN_CURRENCIES.URL },
                { key: 4, as: Link, value: 4, permission: checkResources(PATHS.ADMIN_SHIPPING_MANAGEMENTS.RESOURCES), text: t('ShippingManagement'), icon: 'shipping', to: PATHS.ADMIN_SHIPPING_MANAGEMENTS.URL },
            ]
        },
        {
            permission: checkResources(settingsResources), portals: ['GENERAL'], text: t('Settings'), icon: 'settings', subs: [
                { key: 1, as: Link, value: 1, permission: checkResources(PATHS.ADMIN_SETTINGS_GENERAL.RESOURCES), text: t('GeneralSettings'), icon: 'setting', to: PATHS.ADMIN_SETTINGS_GENERAL.URL },
                { key: 2, as: Link, value: 2, permission: checkResources(PATHS.ADMIN_SETTINGS_BRANCH.RESOURCES), text: t('BranchSettings'), icon: 'setting', to: PATHS.ADMIN_SETTINGS_BRANCH.URL },
            ]
        },
        {
            permission: true, portals: ['GENERAL'], text: t('Help'), icon: 'help circle', subs: [
                { key: 1, as: Link, value: 1, permission: true, text: t('ManualGuide'), icon: 'file pdf', to: PATHS.ADMIN_MANAUAL_GUIDE.URL },
            ]
        },
    ].filter(i => i.portals.includes(portal));
};