import _ from 'lodash';
import React, { useState, } from 'react';
import { Button, Divider, Form, Header, Segment, List, Grid, GridColumn, Label, Tab, Confirm } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { finOffersDataGrid, finRequestOfferDataGrid } from '../../Data Stores';
import { IndividualsSelector, CategoriesSelector, ErrorSegment, ImagesUploaderTemplate, FilterBuilder } from '..';
import { Consts } from '../../res';
import checkInputDir from '../../helpers/checkInputDir';


const SendingMessageOptions = (props) => {
    const { t, data, individualsType, individuals, individualsFieldName, categories, categoriesFieldName, isStock } = props;
    const [selectedIndividuals, setSelectedIndividuals] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [receivers, setReceivers] = useState([]);
    const [receiversLoading, setReceviersLoading] = useState(false);
    const [receiversError, setReceiversError] = useState('');
    const [sendOption, setSendOption] = useState(1);
    const [messageService, setMessageService] = useState(1);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState(null);
    const [images, setImages] = useState([]);
    const [filter, setFilter] = useState(null);
    const [showSendingConfirm, setShowSendingConfirm] = useState(false);

    const onSendingOptionChange = (value) => {
        setReceivers([]);
        setSendOption(value);
    };

    const getReceivers = async () => {
        setReceviersLoading(true);
        try {
            const { data: recvs } =
                sendOption === 1 ? await finOffersDataGrid.getReceivers(individualsType, data.materialId || data.idMaterial, [], [], filter) : sendOption === 2 ? await finOffersDataGrid.getReceivers(individualsType, data.materialId || data.idMaterial, [], selectedCategories.map(c => c.idCategory), filter) : await finOffersDataGrid.getReceivers(individualsType, data.materialId || data.idMaterial, selectedIndividuals.map(s => s.idCustomer), [], filter);
            setReceivers(recvs);
        } catch (err) {
            setReceiversError(err);
        } finally {
            setReceviersLoading(false);
        }
    };

    const onSendMessage = async (isOnlyPreview) => {
        let message;
        try {
            setLoading(true);
            const { materialId, idMaterial, idOffer } = data;
            const msgServices = messageService === 1 ? 'WHATSAPP' : messageService === 2 ? 'EMAIL' : 'WHATSAPP,EMAIL';

            if (individualsType === Consts.CUSTOMERS_TYPES.SUPPLIER) {
                message = {
                    requestOfferId: materialId,
                    extraImgsUrls: images,
                    msgServices
                };
            } else if (individualsType === Consts.CUSTOMERS_TYPES.CUSTOMER)
                if (isStock)
                    message = { materialId: idMaterial, extraImgsUrls: images, msgServices };
                else
                    message = { offerId: idOffer, extraImgsUrls: images, msgServices };

            if (sendOption === 2 && selectedCategories.length) message[categoriesFieldName] = selectedCategories.map(c => c.idCategory);
            if (sendOption === 3 && selectedIndividuals.length) message[individualsFieldName] = selectedIndividuals.map(s => s.idCustomer);

            if (isOnlyPreview) message['isOnlyPreview'] = true;

            const { data: previewData } = individualsType === Consts.CUSTOMERS_TYPES.SUPPLIER
                ? await finRequestOfferDataGrid.sendMessageToSuppliers(message)
                : isStock ? await finOffersDataGrid.sendStock(message)
                    : await finOffersDataGrid.sendMessageToCustomers(message);

            if (previewData) setPreview(previewData);

        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const renderSendOptionDetails = () => {
        switch (sendOption) {
            case 1: return <Header as='h3' textAlign='center'>{t('AutomaticSendText')}</Header>;
            case 2: return <CategoriesSelector data={{ value: selectedCategories, setValue: setSelectedCategories }} categories={categories} />;
            case 3: return <IndividualsSelector data={{ value: selectedIndividuals, setValue: setSelectedIndividuals }} individuals={individuals} individualsType={individualsType} />;
            default:
                return <Header as='h3' textAlign='center'>{t('AutomaticSendText')}</Header>;
        }
    };

    const onCustomizeReceivers = () => {
        const customized = [...new Set(receivers.map(r => r.customerId))];
        const selected = individuals.filter(ind => customized.includes(ind.idCustomer));
        setSendOption(3);
        setSelectedIndividuals(selected);
    };

    const renderReceivers = () => {
        if (receiversLoading) return <Segment padded basic loading={receiversLoading} />;
        if (receiversError) return <ErrorSegment />;
        return (
            <Segment basic>
                {(sendOption === 1 || sendOption === 2) && !!receivers.length && <Button basic negative icon='filter' onClick={onCustomizeReceivers} />}
                {
                    _.orderBy(receivers, ['customerId'], ['asc']).map((r, indx) =>
                        <Label key={indx}
                            color={r.contactType === 'GROUP' ? 'green' : 'blue'}
                            content={r.email ? `${r.contactDisplayName} [${r.email}]` : r.contactDisplayName}
                        />
                    )
                }
            </Segment>
        );
    };

    const renderSendingForm = () => {
        return (
            <>
                <Form widths='equal'>
                    <Form.Group inline >
                        <Form.Radio name='sendOption' label={t('Automatic')} value={1} checked={sendOption === 1} onChange={() => onSendingOptionChange(1)} />
                        <Form.Radio name='sendOption' label={t('Categories')} value={2} checked={sendOption === 2} onChange={() => onSendingOptionChange(2)} />
                        <Form.Radio name='sendOption' label={t('Individuals')} value={3} checked={sendOption === 3} onChange={() => onSendingOptionChange(3)} />
                    </Form.Group>

                    <Divider />

                    <Form.Group inline >
                        <Form.Radio name='msgService' label={t('Email')} value={2} checked={messageService === 2} onChange={() => setMessageService(2)} />
                        <Form.Radio name='msgService' label={t('WhatsApp')} value={1} checked={messageService === 1} onChange={() => setMessageService(1)} />
                        <Form.Radio name='msgService' label={t('Both')} value={3} checked={messageService === 3} onChange={() => setMessageService(3)} />
                    </Form.Group>
                </Form>

                <Divider />

                <Segment basic textAlign='left'>
                    <List bulleted>
                        <List.Item style={{ color: 'red' }}>{t('MakeSureNotSpam')}</List.Item>
                        <List.Item style={{ color: 'red' }}>{t('MakeSureIncludedPreferred')}</List.Item>
                    </List>
                </Segment>

                <Divider />
                {renderSendOptionDetails()}

                <Segment textAlign='left'>
                    <Header as='h4' content={t('Filter')} />
                    <FilterBuilder data={{ value: filter, setValue: setFilter }} />
                </Segment>

                <Divider hidden />

                <Button
                    fluid
                    positive
                    loading={loading}
                    icon='send'
                    labelPosition='left'
                    content={t('Send')}
                    onClick={() => setShowSendingConfirm(true)}
                    disabled={(sendOption === 2 && !selectedCategories.length) || (sendOption === 3 && !selectedIndividuals.length)}
                />

                <Confirm
                    open={showSendingConfirm}
                    content={t('AreYouSure')}
                    onCancel={() => setShowSendingConfirm(false)}
                    onConfirm={() => {
                        onSendMessage(false);
                        setShowSendingConfirm(false);
                    }}
                    cancelButton={t('NO')}
                    confirmButton={t('YES')}
                />

                <Segment>
                    <Header as='h3' content={t('Images')} />
                    <ImagesUploaderTemplate data={{ value: images.map(i => i.imgUrl), setValue: setImages }} />
                </Segment>

                <Divider />
                <Grid columns='equal' stackable verticalAlign='middle'>
                    <GridColumn>
                        <Header as='h3' textAlign='center'> {`${t('Receivers')}: ${receivers.length}`}</Header>
                    </GridColumn>
                    <GridColumn>
                        <Button
                            primary
                            icon='users'
                            onClick={getReceivers}
                            content={t('CheckReceivers')}
                            disabled={receiversLoading || (sendOption === 2 && !selectedCategories.length) || (sendOption === 3 && !selectedIndividuals.length)}
                        />
                    </GridColumn>
                </Grid>
                <Divider />
                {renderReceivers()}
            </>
        );
    };

    const renderMessagePreview = () => {
        const textAreaId = 'textMsgPreview';
        checkInputDir(textAreaId);

        if (!preview) return <Button positive icon='eye' labelPosition="left" content={t('Show')} fluid onClick={() => onSendMessage(true)} loading={loading} />;
        return (
            <Form style={{ dir: 'auto' }}>
                <Form.TextArea
                    id={textAreaId}
                    value={preview.text?.trim()}
                    style={{ minHeight: '35vh', dir: 'auto' }}
                />
                <Form.Checkbox
                    label={t('mustSendOfferImgs')}
                    checked={!!preview.imgs?.length}
                    disabled
                />
                <Form.Checkbox
                    label={t('mustSendMaterialTDS')}
                    checked={!!preview.docs?.length}
                    disabled
                />
            </Form>
        );
    };

    const renderTabs = () => {
        const panes = [
            {
                menuItem: { key: 1, icon: 'send', content: t('Send') },
                render: () => <Tab.Pane attached={false}>{renderSendingForm()}</Tab.Pane>,
            },
            {
                menuItem: { key: 2, icon: 'eye', content: t('Preview') },
                render: () => <Tab.Pane attached={false}>{renderMessagePreview()}</Tab.Pane>,
            },
        ];

        return (
            <Tab menu={{ pointing: true, fluid: true, widths: 2 }} panes={panes} />
        );
    };

    if (error) return <ErrorSegment />;

    return (
        <Segment basic textAlign='center'>
            {renderTabs()}
        </Segment>
    );
};

export default withTranslation()(SendingMessageOptions);