import React, { useState } from 'react';
import { TextBox } from 'devextreme-react/ui/text-box';

const SimpleInput = (props) => {

    const { value, setValue, settingName, placeholder, disabled } = props;
    const [data, setData] = useState(value);

    const onValueChange = (v) => {
        setData(v);
        setValue(settingName, v);
    };

    return (
        <TextBox
            value={data}
            onValueChanged={({ value }) => onValueChange(value)}
            placeholder={placeholder}
            disabled={disabled}
        />
    );
};


export default SimpleInput;