import CustomStore from 'devextreme/data/custom_store';
import i18next from 'i18next';
import request from '../../api';
import { Consts } from '../../res/Consts';
import { store } from '../../store';

const { getState } = store;
const selectedBranchId = () => getState().adminBranches.selectedBranchId;

const generateParamsForRequest = (loadOptions, isMoreOptions) => {
    let params = '?';
    for (let i in loadOptions) {
        if (loadOptions[i])
            params += `${i}=${JSON.stringify(loadOptions[i])}&`;
    }
    if (isMoreOptions)
        return params;
    return params = params.slice(0, -1);
};

export const rolesDataLookup = new CustomStore({
    key: 'roleId',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/access_control/roles/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const employeesDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admins/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const categoriesDataLookup = new CustomStore({
    key: 'idCategory',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/categories/fullinfo/branch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});


export const allResourcesDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/access_control/resources/fullinfo${params}`);
            const resourcesWithDefaultPermissions = data.map(r => ({ ...r, permissions: { ADD: false, EDIT: false, DELETE: false, READ: false, EXPORT: false } }));
            return { data: resourcesWithDefaultPermissions, totalCount };
        } catch (err) {
            return err;
        }
    },
});



export const permissionsDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data } = await request.get(`/access_control/permissions/fullinfo${params}`);
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const finCurrenciesDataLookup = new CustomStore({
    key: 'idCurrency',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data } } = await request.get(`/admin/aw/currencies/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const finMatFamiliesDataLookup = new CustomStore({
    key: 'idFamily',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data } } = await request.get(`/admin/aw/families/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const finMatQualitiesDataLookup = new CustomStore({
    key: 'idQuality',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data } } = await request.get(`/admin/aw/qualities/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const finMatApplicationsDataLookup = new CustomStore({
    key: 'idApplication',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data } } = await request.get(`/admin/aw/applications/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const finMatBrandsDataLookup = new CustomStore({
    key: 'idBrand',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data } } = await request.get(`/admin/aw/brands/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const finMaterialsDataLookup = new CustomStore({
    key: 'idMaterial',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data } } = await request.get(`/admin/aw/materials/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const finMaterialUnitsDataLookup = (materialId, priceCurrencyEquivAt) => new CustomStore({
    key: 'idUnit',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = generateParamsForRequest(loadOptions, true);
            if (materialId)
                params += `materialId=${materialId}&`;
            else
                params += `OBBranchId=${selectedBranchId()}&`;

            if (priceCurrencyEquivAt)
                params += `priceCurrencyEquivAt=${priceCurrencyEquivAt}&`;
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/units${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const finCustomersSuppliersDataLookup = new CustomStore({
    key: 'idCustomer',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data } } = await request.get(`/admin/aw/customers/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const finCustomersTypesDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async () => {
        try {
            const data = [
                { id: 1, type: Consts.CUSTOMERS_TYPES.SUPPLIER, value: i18next.t('Supplier') },
                { id: 2, type: Consts.CUSTOMERS_TYPES.CUSTOMER, value: i18next.t('Customer') }
            ];
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const finShippingCostsDataLookup = new CustomStore({
    key: 'shippingCostId',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data } } = await request.get(`/admin/aw/shipping/costs/${selectedBranchId()}${params}`);
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});


export const finOfferShippingCalculator = (fromCityId, toCityId, currencyId) => new CustomStore({
    load: async () => {
        try {
            const { data: { data } } = await request.get(`/admin/aw/shipping/cost/${selectedBranchId()}/cities/${fromCityId}/${toCityId}?targetCurrencyId=${currencyId}`);
            return { data };
        } catch (err) {
            return err;
        }
    },
});

export const finCurrenciesTransformer = (fromCurrencyId, toCurrencyId, value, at) => new CustomStore({
    load: async () => {
        try {
            const { data: { data } } = await request.get('/admin/aw/currencies/transform', { params: { src: fromCurrencyId, at: at || new Date(), dest: toCurrencyId, val: value } });
            return { data };
        } catch (err) {
            return err;
        }
    },
});

export const regionsDataLookup = new CustomStore({
    key: 'regionId',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount } } = await request.get(`/admin/regions/active/${selectedBranchId()}${params}`);
            return { data, totalCount: allCount };
        } catch (err) {
            return err;
        }
    },
});

export const countriesDataLookup = new CustomStore({
    key: 'countryId',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { countries: data, allCount } } = await request.get(`/admin/countries/active/${selectedBranchId()}${params}`);
            return { data, totalCount: allCount };
        } catch (err) {
            return err;
        }
    },
});

export const citiesDataLookup = new CustomStore({
    key: 'idCity',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount } } = await request.get(`/admin/cities/active/${selectedBranchId()}${params}`);
            return { data, totalCount: allCount };
        } catch (err) {
            return err;
        }
    },
});


export const finAccountsDataLookup = new CustomStore({
    key: 'accountId',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions, true);
            const { data: { data } } = await request.get(`/admin/aw/financial/accounts/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const finSecurityDegreesDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async () => {
        try {
            const data = [
                { id: 1, degree: Consts.FIN_SECURITY_DEGREES.LOW, value: i18next.t('Low') },
                { id: 2, degree: Consts.FIN_SECURITY_DEGREES.MID, value: i18next.t('Mid') },
                { id: 3, degree: Consts.FIN_SECURITY_DEGREES.HIGH, value: i18next.t('High') }
            ];
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const finPayMethodsDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async () => {
        try {
            const data = [
                { id: 1, value: Consts.FIN_BILLS_PAY_METHODS.DELAYED, name: i18next.t('Delayed') },
                { id: 2, value: Consts.FIN_BILLS_PAY_METHODS.CASH, name: i18next.t('Cash') },
            ];
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const finCostCentersDataLookup = new CustomStore({
    key: 'costCenterId',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data } } = await request.get(`/admin/aw/financial/costs_centers/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const finEntryTypesDataLookup = new CustomStore({
    key: 'entryTypeId',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data } } = await request.get(`/admin/aw/financial/entries_types/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const finWarehousesDataLookup = new CustomStore({
    key: 'storeId',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions, true);
            const { data: { data } } = await request.get(`/admin/aw/financial/stores/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const finBillTypesDataLookup = new CustomStore({
    key: 'billTypeId',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions, true);
            const { data: { data } } = await request.get(`/admin/aw/financial/bills_types/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const finSaleContactsDataLookup = new CustomStore({
    key: 'idContact',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions, true);
            const { data: { data } } = await request.get(`/admin/contacts/all/branch/${selectedBranchId()}${params}`);
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});