import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RequirePermission, RequireNoAuth } from '../middlewares/HOC';
import getPaths from './PATHS';

import {
    LandginHome,
    /*Signup,*/ Signin, ResetPassword, Activation,


    AdminDashboard, AdminAdmins, AdminRoles,
    AdminWhatsAppMessages, AdminWhatsAppContacts, AdminWhatsAppMessageForm,
    AdminBranches,
    AdminSettingsGeneral, AdminSettingsBranch, AdminCountries, AdminRegions,
    AdminCurrencies, AdminCurrenciesEquivalents, AdminCategories,
    AdminMaterial, AdminMaterialFamilies, AdminMaterialQualities, AdminMaterialApplications, AdminMaterialBrands,
    AdminCustomers, AdminOffersManagment, AdminOffersSales, AdminRequestMaterials, AdminRequestedMaterials, AdminOffersStock,
    AdminShippingManagements, AdminFinWarehouseTransportations,

    AdminFinPeriods, AdminFinAccounts, AdminFinCostCenters, AdminFinEntries, AdminFinEntryTypes,
    AdminFinWarehouses, AdminFinBills, AdminFinNewBillForm, AdminFinBillTypes, AdminFinBillsIndex,
    AdminFinReportsMaterialInventory, AdminFinReportsMaterialMovement,
    AdminFinReportsLedger, AdminFinReportsTrialBalance, AdminFinReportsProfitsAndLosses,
    AdminFinProformaInvoice,

    AdminFinSalesCustomers, AdminFinSalesDeals, AdminFinSalesContacts, AdminFinSalesReservations,

    AdminManualGuide
} from '../pages';

const Router = ({ selectedBranchId }) => {
    const PATHS = getPaths(selectedBranchId);
    return <Switch>
        {/* Basic Routes */}
        <Route path={PATHS.HOME.URL} exact component={LandginHome} />
        <Route path={PATHS.RESET_PASSWORD.URL} exact component={ResetPassword} />
        <Route path={PATHS.ACTIVATION.URL} exact component={Activation} />

        {/* <Route path="/signup" exact component={RequireNoAuth(Signup)} /> */}
        <Route path={PATHS.SIGNIN.URL} exact component={RequireNoAuth(Signin)} />
        {/* <Route path="/profile" exact component={Profile} /> */}



        <Route path={PATHS.ADMIN_DASHBOARD.URL} exact component={RequirePermission(AdminDashboard, PATHS.ADMIN_DASHBOARD.RESOURCES)} />
        <Route path={PATHS.ADMIN_NEW_MESSAGE.URL} exact component={RequirePermission(AdminWhatsAppMessageForm, PATHS.ADMIN_NEW_MESSAGE.RESOURCES, PATHS.ADMIN_NEW_MESSAGE.PERMISSIONS, 'OR')} />
        <Route path={PATHS.ADMIN_MESSAGES.URL} exact component={RequirePermission(AdminWhatsAppMessages, PATHS.ADMIN_MESSAGES.RESOURCES)} />
        <Route path={PATHS.ADMIN_CONTACTS.URL} exact component={RequirePermission(AdminWhatsAppContacts, PATHS.ADMIN_CONTACTS.RESOURCES)} />
        <Route path={PATHS.ADMIN_BRANCHES.URL} exact component={RequirePermission(AdminBranches, PATHS.ADMIN_BRANCHES.RESOURCES)} />
        <Route path={PATHS.ADMIN_EMPLOYEES.URL} exact component={RequirePermission(AdminAdmins, PATHS.ADMIN_EMPLOYEES.RESOURCES)} />
        <Route path={PATHS.ADMIN_ROLES.URL} exact component={RequirePermission(AdminRoles, PATHS.ADMIN_ROLES.RESOURCES)} />

        <Route path={PATHS.ADMIN_CURRENCIES.URL} exact component={RequirePermission(AdminCurrencies, PATHS.ADMIN_CURRENCIES.RESOURCES)} />
        <Route path={PATHS.ADMIN_CURRENCIES_EQUIVALENTS.URL} exact component={RequirePermission(AdminCurrenciesEquivalents, PATHS.ADMIN_CURRENCIES_EQUIVALENTS.RESOURCES)} />
        <Route path={PATHS.ADMIN_MATERIALS.URL} exact component={RequirePermission(AdminMaterial, PATHS.ADMIN_MATERIALS.RESOURCES)} />
        <Route path={PATHS.ADMIN_MATERIALS_FAMILIES.URL} exact component={RequirePermission(AdminMaterialFamilies, PATHS.ADMIN_MATERIALS_FAMILIES.RESOURCES)} />
        <Route path={PATHS.ADMIN_MATERIALS_QUALITIES.URL} exact component={RequirePermission(AdminMaterialQualities, PATHS.ADMIN_MATERIALS_QUALITIES.RESOURCES)} />
        <Route path={PATHS.ADMIN_MATERIALS_APPLICATIONS.URL} exact component={RequirePermission(AdminMaterialApplications, PATHS.ADMIN_MATERIALS_APPLICATIONS.RESOURCES)} />
        <Route path={PATHS.ADMIN_MATERIALS_BRANDS.URL} exact component={RequirePermission(AdminMaterialBrands, PATHS.ADMIN_MATERIALS_BRANDS.RESOURCES)} />
        <Route path={PATHS.ADMIN_CUSTOMERS.URL} exact component={RequirePermission(AdminCustomers, PATHS.ADMIN_CUSTOMERS.RESOURCES, PATHS.ADMIN_CUSTOMERS.PERMISSIONS, 'OR')} />
        <Route path={PATHS.ADMIN_OFFERS.URL} exact component={RequirePermission(AdminOffersManagment, PATHS.ADMIN_OFFERS.RESOURCES, PATHS.ADMIN_OFFERS.PERMISSIONS, 'OR')} />
        <Route path={PATHS.ADMIN_FINAL_OFFERS.URL} exact component={RequirePermission(AdminOffersSales, PATHS.ADMIN_FINAL_OFFERS.RESOURCES)} />
        <Route path={PATHS.ADMIN_STOCK_OFFERS.URL} exact component={RequirePermission(AdminOffersStock, PATHS.ADMIN_STOCK_OFFERS.RESOURCES)} />
        <Route path={PATHS.ADMIN_REQUEST_MATERIALS.URL} exact component={RequirePermission(AdminRequestMaterials, PATHS.ADMIN_REQUEST_MATERIALS.RESOURCES)} />
        <Route path={PATHS.ADMIN_REQUESTED_MATERIALS.URL} exact component={RequirePermission(AdminRequestedMaterials, PATHS.ADMIN_REQUEST_MATERIALS.RESOURCES)} />
        <Route path={PATHS.ADMIN_SHIPPING_MANAGEMENTS.URL} exact component={RequirePermission(AdminShippingManagements, PATHS.ADMIN_SHIPPING_MANAGEMENTS.RESOURCES)} />
        <Route path={PATHS.ADMIN_SETTINGS_GENERAL.URL} exact component={RequirePermission(AdminSettingsGeneral, PATHS.ADMIN_SETTINGS_GENERAL.RESOURCES)} />
        <Route path={PATHS.ADMIN_SETTINGS_BRANCH.URL} exact component={RequirePermission(AdminSettingsBranch, PATHS.ADMIN_SETTINGS_BRANCH.RESOURCES)} />
        <Route path={PATHS.ADMIN_COUNTRIES.URL} exact component={RequirePermission(AdminCountries, PATHS.ADMIN_COUNTRIES.RESOURCES)} />
        <Route path={PATHS.ADMIN_REGIONS.URL} exact component={RequirePermission(AdminRegions, PATHS.ADMIN_REGIONS.RESOURCES)} />
        <Route path={PATHS.ADMIN_CATEGORIES.URL} exact component={RequirePermission(AdminCategories, PATHS.ADMIN_CATEGORIES.RESOURCES)} />
        <Route path={PATHS.ADMIN_MANAUAL_GUIDE.URL} exact component={AdminManualGuide} />

        {/* change permissions downh here */}
        <Route path={PATHS.ADMIN_FIN_PERIODS.URL} exact component={RequirePermission(AdminFinPeriods, PATHS.ADMIN_FIN_PERIODS.RESOURCES)} />
        <Route path={PATHS.ADMIN_FIN_ACCOUNTS.URL} exact component={RequirePermission(AdminFinAccounts, PATHS.ADMIN_FIN_ACCOUNTS.RESOURCES)} />
        <Route path={PATHS.ADMIN_FIN_COST_CENTERS.URL} exact component={RequirePermission(AdminFinCostCenters, PATHS.ADMIN_FIN_COST_CENTERS.RESOURCES)} />
        <Route path={PATHS.ADMIN_FIN_ENTRIES.URL} exact component={RequirePermission(AdminFinEntries, PATHS.ADMIN_FIN_ENTRIES.RESOURCES)} />
        <Route path={PATHS.ADMIN_FIN_ENTRY_TYPES.URL} exact component={RequirePermission(AdminFinEntryTypes, PATHS.ADMIN_FIN_ENTRY_TYPES.RESOURCES)} />
        <Route path={PATHS.ADMIN_FIN_WAREHOUSES.URL} exact component={RequirePermission(AdminFinWarehouses, PATHS.ADMIN_FIN_WAREHOUSES.RESOURCES)} />
        <Route path={PATHS.ADMIN_FIN_WAREHOUSES_TRANSPORTATIONS.URL} exact component={RequirePermission(AdminFinWarehouseTransportations, PATHS.ADMIN_FIN_WAREHOUSES_TRANSPORTATIONS.RESOURCES)} />
        <Route path={PATHS.ADMIN_FIN_BILLS.URL} exact component={RequirePermission(AdminFinBills, PATHS.ADMIN_FIN_BILLS.RESOURCES)} />
        <Route path={PATHS.ADMIN_FIN_BILL_TYPES.URL} exact component={RequirePermission(AdminFinBillTypes, PATHS.ADMIN_FIN_BILL_TYPES.RESOURCES)} />
        <Route path={PATHS.ADMIN_FIN_BILLS_INDEX.URL} exact component={RequirePermission(AdminFinBillsIndex, PATHS.ADMIN_FIN_BILLS.RESOURCES)} />
        <Route path={PATHS.ADMIN_FIN_BILLS_NEW_FORM.URL} exact component={RequirePermission(AdminFinNewBillForm, PATHS.ADMIN_FIN_BILLS_NEW_FORM.RESOURCES, PATHS.ADMIN_NEW_MESSAGE.PERMISSIONS, 'OR')} />
        <Route path={PATHS.ADMIN_FIN_PROFORMA_INVOICE.URL} exact component={RequirePermission(AdminFinProformaInvoice, PATHS.ADMIN_FIN_PROFORMA_INVOICE.RESOURCES)} />

        <Route path={PATHS.ADMIN_FIN_REPORTS_MATERIAL_INVENTORY.URL} exact component={RequirePermission(AdminFinReportsMaterialInventory, PATHS.ADMIN_FIN_REPORTS_MATERIAL_INVENTORY.RESOURCES)} />
        <Route path={PATHS.ADMIN_FIN_REPORTS_MATERIAL_MOVEMENT.URL} exact component={RequirePermission(AdminFinReportsMaterialMovement, PATHS.ADMIN_FIN_REPORTS_MATERIAL_MOVEMENT.RESOURCES)} />
        <Route path={PATHS.ADMIN_FIN_REPORTS_LEDGER.URL} exact component={RequirePermission(AdminFinReportsLedger, PATHS.ADMIN_FIN_REPORTS_LEDGER.RESOURCES)} />
        <Route path={PATHS.ADMIN_FIN_REPORTS_TRIAL_BALANCE.URL} exact component={RequirePermission(AdminFinReportsTrialBalance, PATHS.ADMIN_FIN_REPORTS_TRIAL_BALANCE.RESOURCES)} />
        <Route path={PATHS.ADMIN_FIN_REPORTS_PROFITS_AND_LOSSES.URL} exact component={RequirePermission(AdminFinReportsProfitsAndLosses, PATHS.ADMIN_FIN_REPORTS_PROFITS_AND_LOSSES.RESOURCES)} />

        <Route path={PATHS.ADMIN_FIN_SALES_CUSTOMERS.URL} exact component={RequirePermission(AdminFinSalesCustomers, PATHS.ADMIN_FIN_SALES_CUSTOMERS.RESOURCES, PATHS.ADMIN_FIN_SALES_CUSTOMERS.PERMISSIONS, 'OR')} />
        <Route path={PATHS.ADMIN_FIN_SALES_DEALS.URL} exact component={RequirePermission(AdminFinSalesDeals, PATHS.ADMIN_FIN_SALES_DEALS.RESOURCES)} />
        <Route path={PATHS.ADMIN_FIN_SALES_CONTACTS.URL} exact component={RequirePermission(AdminFinSalesContacts, PATHS.ADMIN_FIN_SALES_CONTACTS.RESOURCES)} />
        <Route path={PATHS.ADMIN_FIN_SALES_RESERVATIONS.URL} exact component={RequirePermission(AdminFinSalesReservations, PATHS.ADMIN_FIN_SALES_RESERVATIONS.RESOURCES)} />


        {/* {renderRoutes(user.roleId)} */}

        {/* Invalid Routes */}
        <Route>
            <Route path="*" exact component={LandginHome} />
            <Redirect from='*' to='/' />
        </Route>

    </Switch>;
};

export default Router;
