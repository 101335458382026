import React from 'react';
import { withTranslation } from 'react-i18next';
import { Segment, Header, Form, Button } from 'semantic-ui-react';

const ChangePasswordForm = ({ onChange, onSubmit, formData, t, loading }) => {
    const { password, confirm, error } = formData;
    const onFocus = () => {
        onChange({
            props: 'error', value: ''
        });
    };
    const onActionPressed = () => {
        if (validate()) {
            onSubmit();
        }
    }
    const validate = () => {
        if (!password) {
            onChange({
                props: 'error', value: { ...error, password: t('Required') }
            });
            return false;
        }
        if (!confirm) {
            onChange({
                props: 'error', value: { ...error, confirm: t('Required') }
            });
            return false;
        }
        if (password.length <= 6) {
            onChange({
                props: 'error', value: { ...error, confirm: t('ErrorPassword') }
            });
            return false;
        }
        if (password !== confirm) {
            onChange({
                props: 'error', value: { ...error, password: t('PasswordMustMatch'), confirm: t('PasswordMustMatch') }
            });
            return false;
        }
        onFocus();
        return true;
    };
    return (
        <Segment>
            <Header>{t('ChangePassword')}</Header>
            <Form loading={loading}>
                <Form.Group widths='2'>
                    <Form.Input
                        type='password'
                        label={t('Password')}
                        placeholder='*********'
                        required
                        icon='lock'
                        maxLength={20}
                        minLength={7}
                        iconPosition='left'
                        value={password}
                        onChange={(e, data) => onChange({ props: 'password', value: data.value })}
                        error={error.password}
                        onFocus={onFocus}
                        autoComplete='on'
                    />
                    <Form.Input
                        type='password'
                        label={t('ConfirmPassword')}
                        placeholder='*********'
                        required
                        icon='lock'
                        maxLength={20}
                        minLength={7}
                        iconPosition='left'
                        value={confirm}
                        onChange={(e, data) => onChange({ props: 'confirm', value: data.value })}
                        error={error.confirm}
                        onFocus={onFocus}
                        autoComplete='on'
                    />
                </Form.Group>
                <Button onClick={onActionPressed} primary>{t('Save')}</Button>
            </Form>
        </Segment>
    );
};

export default withTranslation()(ChangePasswordForm);
