import { combineReducers } from 'redux';

import PortalReducer from './admin section/PortalReducer';
import SigninReducer from './landing section/SigninReducer';
import SignupReducer from './landing section/SignupReducer';
import ForgotPasswordReducer from './landing section/ForgotPasswordReducer';
import ResetPasswordReducer from './landing section/ResetPasswordReducer';
import ActicationCodeReducer from './landing section/ActivationReducer';
import ContactUsReducer from './landing section/ContactUsReducer';
import CountriesReducer from './landing section/CountriesReducer';
import ProfileReducer from './landing section/ProfileReducer';


import WhatsAppContactsReducer from './admin section/WhatsApp/WhatsAppContactsReducer';
import AdminBranchesReducer from './admin section/BranchesReducer';
import AdminMaytapiReducer from './admin section/MaytapiReducer';


export default combineReducers({
    auth: SigninReducer,
    signup: SignupReducer,
    forgot: ForgotPasswordReducer,
    resetPassword: ResetPasswordReducer,
    activation: ActicationCodeReducer,
    contactus: ContactUsReducer,
    countries: CountriesReducer,
    profile: ProfileReducer,
    portal: PortalReducer,

    adminWhatsappContacts: WhatsAppContactsReducer,
    adminBranches: AdminBranchesReducer,
    adminMaytapi: AdminMaytapiReducer,
});