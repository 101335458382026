import React from 'react';
import { Header } from 'semantic-ui-react';

const UnderlinedHeader = ({ title, description, color }) => {
    return (
        <>
            <Header as='h1' color={color || 'green'} style={{ marginBottom: 0, padding: 0 }}>{title}</Header>
            <Header as='h1' color={color || 'green'} style={{ margin: 0, marginTop: '-3vh', padding: 0, letterSpacing: -1 }}>____</Header>
            {description ? <Header as='h5'>{description}</Header> : null}
        </>
    );
};

export default UnderlinedHeader;