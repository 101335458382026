import { Consts } from "../../../../res";

export default (type) => {
    switch (type) {
        case Consts.BRANCH_SETTINGS_TYPES.OFFER_MSG_CONSUMER_TEMPLATE_EN:
            return OFFER_TO_CUSTOMER_PLACEHOLDERS;
        case Consts.BRANCH_SETTINGS_TYPES.MATERIAL_MSG_CONSUMER_TEMPLATE_EN:
            return MATERIAL_TO_CUSTOMER_PLACEHOLDERS;
        case Consts.BRANCH_SETTINGS_TYPES.REQ_OFFER_MSG_SUPPLIER_TEMPLATE_EN:
            return OFFER_REQUEST_TO_SUPPLIER_PLACEHOLDERS;
        default: return [];
    }
};


const OFFER_TO_CUSTOMER_PLACEHOLDERS = {
    offerId: { value: 'offerId', name: 'OfferId' },
    OBBranchName: { value: 'OBBranchName', name: 'BranchName' },
    NOW_LOCAL_DATE: { value: 'NOW_LOCAL_DATE', name: 'SendingDate' },
    // receiverName: { value: 'receiverName', name: 'CustomerName' },
    materialIdentifier: { value: 'materialIdentifier', name: 'MaterialName' },
    offerPrice: { value: 'offerPrice', name: 'Price' },
    offerPriceCurrency: { value: 'offerPriceCurrency', name: 'Currency' },
    offerQuantity: { value: 'offerQuantity', name: 'Quantity' },
    offerQuantityUnit: { value: 'offerQuantityUnit', name: 'Unit' },
    // offerStatus: { value: 'offerStatus', name: 'Status' },
    // offereNotes: { value: 'offereNotes', name: 'Notes' },
    // offerCity: { value: 'offerCity', name: 'From' },
    // offerToCity: { value: 'offerToCity', name: 'To' },
    // offerShippingCost: { value: 'offerShippingCost', name: 'ShippingCost' },
    mustSendOfferImgs: { value: 'mustSendOfferImgs', name: 'mustSendOfferImgs', attachment: true },
    mustSendMaterialTDS: { value: 'mustSendMaterialTDS', name: 'mustSendMaterialTDS', attachment: true },
};

const MATERIAL_TO_CUSTOMER_PLACEHOLDERS = {
    OBBranchName: { value: 'OBBranchName', name: 'BranchName' },
    NOW_LOCAL_DATE: { value: 'NOW_LOCAL_DATE', name: 'SendingDate' },
    // receiverName: { value: 'receiverName', name: 'CustomerName' },
    materialIdentifier: { value: 'materialIdentifier', name: 'MaterialName' },
    mustSendMaterialTDS: { value: 'mustSendMaterialTDS', name: 'mustSendMaterialTDS', attachment: true },
};

const OFFER_REQUEST_TO_SUPPLIER_PLACEHOLDERS = {
    OBBranchName: { value: 'OBBranchName', name: 'BranchName' },
    NOW_LOCAL_DATE: { value: 'NOW_LOCAL_DATE', name: 'SendingDate' },
    // receiverName: { value: 'receiverName', name: 'CustomerName' },
    requestNotes: { value: 'requestNotes', name: 'Notes' },
    materialIdentifier: { value: 'materialIdentifier', name: 'MaterialName' },
    mustSendMaterialTDS: { value: 'mustSendMaterialTDS', name: 'mustSendMaterialTDS', attachment: true },
};
