import React, { useState, useEffect } from 'react';
import { Segment } from 'semantic-ui-react';
import { SelectBox } from 'devextreme-react/ui/select-box';
import { ErrorSegment } from '../../';

const LookupInput = (props) => {

    const { dataStore, value, setValue, valueExpr, displayExpr, settingName, placeholder, disabled } = props;
    const [val, setVal] = useState(parseInt(value));
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');


    useEffect(() => {
        getData();
        //eslint-disable-next-line
    }, []);

    const getData = async () => {
        setLoading(true);
        try {
            const { data } = await dataStore.load();
            setData(data);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const onValueChange = (v) => {
        setVal(v);
        setValue(settingName, v);
    };

    if (loading) return <Segment basic loading={loading} padded />;
    if (error) return <ErrorSegment />;

    return (
        <SelectBox
            dataSource={{
                store: data,
                paginate: true,
                pageSize: 50
            }}
            searchEnabled
            placeholder={placeholder}
            valueExpr={valueExpr}
            displayExpr={displayExpr}
            value={val}
            onValueChanged={({ value }) => onValueChange(value)}
            disabled={disabled}
        />
    );
};


export default LookupInput;