import React, { useState, useEffect } from 'react';
import FileUploader from 'devextreme-react/file-uploader';
import { Grid, GridColumn, Icon, Image, Segment } from 'semantic-ui-react';
import { BASE_URL } from '../../api';

const ProfileImageUploader = ({ data, Url }) => {
    const uploadUrl = Url ? `${BASE_URL}${Url}` : `${BASE_URL}/img/upload`;
    const [imageSource, setImageSource] = useState(data.value || '');
    const [loading, setLoading] = useState(false);
    const allowedFileExtensions = ['.jpg', '.jpeg', '.png'];

    useEffect(() => {
        let open = XMLHttpRequest.prototype.open;
        XMLHttpRequest.prototype.open = function () {
            open.apply(this, arguments);
            this.withCredentials = true;
        }
    }, []);

    const onUploaded = (e) => {
        const file = e.file;
        const fileReader = new FileReader();
        fileReader.onload = () => {
            setImageSource(fileReader.result);
            setLoading(false);
        };
        fileReader.readAsDataURL(file);

        data.setValue(JSON.parse(e.request.response).imgUrl);
    };

    return (
        <div>
            <Grid stackable columns={2}>
                <GridColumn textAlign='center' verticalAlign='middle'>
                    <FileUploader
                        id="file-uploader"
                        multiple={false}
                        accept="image/x-png,image/jpg,image/jpeg"
                        allowedFileExtensions={allowedFileExtensions}
                        uploadMode="instantly"
                        uploadUrl={uploadUrl}
                        visible
                        allowCanceling
                        name='img'
                        maxFileSize={10000000}
                        onUploaded={onUploaded}
                        onUploadStarted={() => setLoading(true)}
                        onUploadAborted={() => setLoading(false)}
                        onUploadError={() => setLoading(false)}
                    />
                </GridColumn>

                <GridColumn textAlign='center'>
                    {
                        loading ? <Segment size='huge' basic placeholder loading /> :
                            imageSource ? <Image src={imageSource} size='small' rounded centered /> :
                                <Icon name='picture' circular size='large' />
                    }
                </GridColumn>
            </Grid>
        </div>
    );
}



export default ProfileImageUploader;