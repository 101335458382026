import React, { createRef } from 'react';
import { withTranslation } from 'react-i18next';
import DataGrid, { Column, Editing, RequiredRule } from 'devextreme-react/data-grid';


const PhoneNumbersGrid = ({ t, data }) => {
    const gridRef = createRef();
    const phones = data.value ? data.value.split(',').map(phone => ({ phone })) : [];
    const handleDataStore = (DS) => data.setValue(DS.map(p => p.phone).join());

    return (
        <DataGrid
            ref={gridRef}
            dataSource={phones}
            showBorders
            showColumnLines
            columnHidingEnabled
            wordWrapEnabled
            columnAutoWidth
            onRowUpdated={() => handleDataStore(gridRef.current._instance.getDataSource()._items)}
            onRowInserted={() => handleDataStore(gridRef.current._instance.getDataSource()._items)}
            onRowRemoved={() => handleDataStore(gridRef.current._instance.getDataSource()._items)}
        >
            <Editing
                mode='row'
                useIcons
                allowUpdating
                allowAdding
                allowDeleting
                refreshMode='reshape'
            />
            <Column dataField='phone' dataType='number' caption={t('Numbers')} alignment='center'>
                <RequiredRule />
            </Column>
        </DataGrid>
    );
};

export default withTranslation()(PhoneNumbersGrid);
