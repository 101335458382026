import wall1Image from './images/wall1.jpg';

export const Styles = {
    blockquoteUp: {
        position: 'absolute',
        fontSize: 150,
        color: '#21BA45',
        fontFamily: 'Georgia',
        opacity: 0.5,
        left: 0,
    },
    blockquoteDown: {
        position: 'absolute',
        fontSize: 150,
        right: 0,
        bottom: -30,
        color: '#21BA45',
        fontFamily: 'Georgia',
        opacity: 0.3
    },
    background: {
        backgroundImage: `url(${wall1Image})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        margin: 0,
        padding: 0,
        paddingTop: 50,
        paddingBottom: 50
    },
    noExtraSpaces: {
        padding: 0,
        margin: 1
    }
};