import React, { useEffect, useState } from 'react';
import FileUploader from 'devextreme-react/file-uploader';
import { BASE_URL } from '../../api';

const ImagesUploader = ({ data, Url }) => {
    const [showFilesList, setShowFilesList] = useState(true);
    const uploadUrl = Url ? `${BASE_URL}${Url}` : `${BASE_URL}/img/upload`;
    const allowedFileExtensions = ['.jpg', '.jpeg', '.png'];

    useEffect(() => {
        let open = XMLHttpRequest.prototype.open;
        XMLHttpRequest.prototype.open = function () {
            open.apply(this, arguments);
            this.withCredentials = true;
        }
    }, []);

    const onUploaded = (e) => {
        // const file = e.file;
        // const fileReader = new FileReader();
        // fileReader.readAsDataURL(file);
        // fileReader.onload = () => {

        // };

        data.setValue([...data.value, JSON.parse(e.request.response).imgUrl]);
    };

    return (
        <div>
            <FileUploader
                multiple
                accept="image/x-png,image/jpg,image/jpeg"
                allowedFileExtensions={allowedFileExtensions}
                uploadMode="instantly"
                uploadUrl={uploadUrl}
                showFileList={showFilesList}
                allowCanceling
                visible
                name='img'
                activeStateEnabled
                onUploaded={onUploaded}
                maxFileSize={2000000}
                onFilesUploaded={() => setShowFilesList(false)}
                onValueChanged={() => setShowFilesList(true)}
            />
        </div >
    );
}



export default ImagesUploader;