import React from 'react';
import { Consts } from '../../../res';
import LookupInput from './LookupInput';
import SimpleInput from './SimpleInput';
import UploadFileInput from './UploadFileInput';
import MsgTemplates from './MsgTemplates';

const SettingRowEditor = (props) => {

    const renderInput = () => {
        switch (props.type) {
            case Consts.SETTINGS_TYPES_INPUT.SIMPLE: return <SimpleInput {...props} />;
            case Consts.SETTINGS_TYPES_INPUT.LOOKUP: return <LookupInput {...props} />;
            case Consts.SETTINGS_TYPES_INPUT.MSG_TEMPLATE: return <MsgTemplates {...props} />;
            case Consts.SETTINGS_TYPES_INPUT.UPLOAD_FILE: return <UploadFileInput {...props} />;
            default:
                return <SimpleInput {...props} />;
        }
    };
    return (
        <div>
            {renderInput()}
        </div>
    );
};


export default SettingRowEditor;