import { lazy } from 'react';

// LANDING SECTION
const LandginHome = lazy(() => import('./landing section/Home'));
const LandingContactUs = lazy(() => import('./landing section/ContactUs'));
const Signup = lazy(() => import('./landing section/Auths/Signup'));
const Signin = lazy(() => import('./landing section/Auths/Signin'));
const ResetPassword = lazy(() => import('./landing section/Auths/ResetPassword'));
const Activation = lazy(() => import('./landing section/Auths/Activation'));
const Profile = lazy(() => import('./landing section/Auths/Profile'));

// USER SECTION
const UserDashboard = lazy(() => import('./user section/Dashboard'));


// ADMIN SECTION
const AdminDashboard = lazy(() => import('./admin section/Dashboard'));
const AdminAdmins = lazy(() => import('./admin section/Admins'));
const AdminRoles = lazy(() => import('./admin section/Roles/Roles'));
const AdminWhatsAppMessages = lazy(() => import('./admin section/WhatsApp/WhatsAppMessages'));
const AdminWhatsAppContacts = lazy(() => import('./admin section/WhatsApp/WhatsAppContacts'));
const AdminWhatsAppMessageForm = lazy(() => import('./admin section/WhatsApp/WhatsAppMessageForm'));
const AdminBranches = lazy(() => import('./admin section/Branches/Branches'));
// ADMIN FINANCIAL SECTION 
const AdminCategories = lazy(() => import('./admin section/Settings/Categories'));
const AdminCurrencies = lazy(() => import('./admin section/Financial/Currencies/Currencies'));
const AdminCurrenciesEquivalents = lazy(() => import('./admin section/Financial/Currencies/Equivalents'));
const AdminMaterialFamilies = lazy(() => import('./admin section/Financial/Materials/Families'));
const AdminMaterialQualities = lazy(() => import('./admin section/Financial/Materials/Qualities'));
const AdminMaterialApplications = lazy(() => import('./admin section/Financial/Materials/Applications'));
const AdminMaterialBrands = lazy(() => import('./admin section/Financial/Materials/Brands'));
const AdminMaterial = lazy(() => import('./admin section/Financial/Materials/Materials'));
const AdminCustomers = lazy(() => import('./admin section/Financial/Customers/Customers'));
const AdminOffersManagment = lazy(() => import('./admin section/Financial/Offers/Offers'));
const AdminOffersSales = lazy(() => import('./admin section/Financial/Offers/FinalOffers'));
const AdminOffersStock = lazy(() => import('./admin section/Financial/Offers/StockOffers'));
const AdminRequestMaterials = lazy(() => import('./admin section/Financial/Offers/RequestMaterial'));
const AdminRequestedMaterials = lazy(() => import('./admin section/Financial/Offers/RequestedMaterials'));
const AdminShippingManagements = lazy(() => import('./admin section/Financial/Shipping/ShippingManagement'));
const AdminSettingsGeneral = lazy(() => import('./admin section/Settings/GeneralSettings'));
const AdminSettingsBranch = lazy(() => import('./admin section/Settings/BranchSettings'));
const AdminCountries = lazy(() => import('./admin section/Settings/Countries/Countries'));
const AdminRegions = lazy(() => import('./admin section/Settings/Countries/Regions'));
const AdminManualGuide = lazy(() => import('./admin section/Help/ManualGuide'));
const AdminFinPeriods = lazy(() => import('./admin section/Financial/Periods'));
const AdminFinAccounts = lazy(() => import('./admin section/Financial/Accounts'));
const AdminFinCostCenters = lazy(() => import('./admin section/Financial/Cost Centers'));
const AdminFinEntries = lazy(() => import('./admin section/Financial/Entries'));
const AdminFinEntryTypes = lazy(() => import('./admin section/Financial/Entries/EntryTypes'));
const AdminFinWarehouses = lazy(() => import('./admin section/Financial/Warehouses'));
const AdminFinBillsIndex = lazy(() => import('./admin section/Financial/Bills'));
const AdminFinBills = lazy(() => import('./admin section/Financial/Bills/Bills'));
const AdminFinBillTypes = lazy(() => import('./admin section/Financial/Bills/BillTypes'));
const AdminFinNewBillForm = lazy(() => import('./admin section/Financial/Bills/NewBillForm'));
const AdminFinReportsMaterialInventory = lazy(() => import('./admin section/Financial/Reports/Material_Inventory'));
const AdminFinReportsMaterialMovement = lazy(() => import('./admin section/Financial/Reports/Material_Movement'));
const AdminFinReportsLedger = lazy(() => import('./admin section/Financial/Reports/Ledger'));
const AdminFinReportsTrialBalance = lazy(() => import('./admin section/Financial/Reports/Trial Balance'));
const AdminFinReportsProfitsAndLosses = lazy(() => import('./admin section/Financial/Reports/Profits and Losses'));
const AdminFinProformaInvoice = lazy(() => import('./admin section/Financial/ProformaInvoice'));
const AdminFinWarehouseTransportations = lazy(() => import('./admin section/Financial/Warehouses/Transporations'));
const AdminFinSalesCustomers = lazy(() => import('./admin section/Financial/Sales/Customers'));
const AdminFinSalesDeals = lazy(() => import('./admin section/Financial/Sales/Deals'));
const AdminFinSalesContacts = lazy(() => import('./admin section/Financial/Sales/Contacts'));
const AdminFinSalesReservations = lazy(() => import('./admin section/Financial/Sales/Reservations'));



export {
    LandginHome,
    Signup, Signin, ResetPassword, Profile, Activation, LandingContactUs,

    UserDashboard,

    AdminDashboard, AdminAdmins, AdminRoles,
    AdminWhatsAppMessages, AdminWhatsAppContacts, AdminWhatsAppMessageForm,
    AdminBranches,
    AdminSettingsGeneral, AdminSettingsBranch, AdminCountries, AdminRegions,
    AdminCurrencies, AdminCurrenciesEquivalents, AdminCategories,
    AdminMaterial, AdminMaterialFamilies, AdminMaterialQualities, AdminMaterialApplications, AdminMaterialBrands,
    AdminCustomers, AdminOffersManagment, AdminOffersSales, AdminRequestMaterials, AdminRequestedMaterials, AdminOffersStock,
    AdminShippingManagements,

    AdminFinPeriods, AdminFinAccounts, AdminFinCostCenters, AdminFinEntries, AdminFinEntryTypes,
    AdminFinWarehouses, AdminFinBills, AdminFinNewBillForm, AdminFinBillTypes, AdminFinBillsIndex,
    AdminFinWarehouseTransportations,

    AdminFinReportsMaterialInventory, AdminFinReportsMaterialMovement,
    AdminFinReportsLedger, AdminFinReportsTrialBalance, AdminFinReportsProfitsAndLosses,
    AdminFinProformaInvoice,

    AdminFinSalesCustomers, AdminFinSalesDeals, AdminFinSalesContacts, AdminFinSalesReservations,

    AdminManualGuide
};