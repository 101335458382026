import {
    ADMIN_SELECT_PORTAL,
} from '../../actions/types';

const INITIAL_STATE = {
    selectedPortal: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_SELECT_PORTAL:
            return { selectedPortal: action.payload };
        default:
            return state;
    }
};

