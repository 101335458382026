import i18n from 'i18next';
import {
    POST_FORGOTPASSWORD_START,
    POST_FORGOTPASSWORD_FAIL,
    POST_FORGOTPASSWORD,
    FORGOTPASSWORD_INFO_CHANGED
} from '../types';
import { badReq, noConnection } from '../../helpers/errors';
import request from '../../api';


export const forgotInfoChanged = ({ props, value }) => {
    return { type: FORGOTPASSWORD_INFO_CHANGED, payload: { props, value } };
};

export const postForgotPassword = (userName, onDecline) => async dispatch => {
    dispatch({ type: POST_FORGOTPASSWORD_START });
    try {
        await request.get(`/auth/account/resetpassword/code/${userName}`);
        dispatch({ type: POST_FORGOTPASSWORD });
        alert(i18n.t('CheckEmail'));
        onDecline();
    } catch (err) {
        dispatch({ type: POST_FORGOTPASSWORD_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        if (err.response) return badReq();
        else noConnection();
    }
};