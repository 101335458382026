import { createMedia } from "@artsy/fresnel"

const ExampleAppMedia = createMedia({
    breakpoints: {
        mobile: 240,
        tablet: 768,
        computer: 992,
        largeScreen: 1200,
        widescreen: 1920
    },
})

// Generate CSS to be injected into the head
export const mediaStyle = ExampleAppMedia.createMediaStyle()
export const { Media, MediaContextProvider } = ExampleAppMedia;