export default (elementId) => {

    const elementRef = document.getElementById(elementId);
    if (!elementRef) return;

    if (elementRef.value.length >= 1) {
        var x = new RegExp("[\x00-\x80]+"); // is ascii

        var isAscii = x.test(elementRef.value[0]);

        if (isAscii) {
            elementRef.style.direction = 'ltr';
            elementRef.style.textAlign = 'left';
        }
        else {
            elementRef.style.direction = 'rtl';
            elementRef.style.textAlign = 'right';
        }
    }

};