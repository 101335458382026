import React from 'react';
import { Segment, Image } from 'semantic-ui-react';

const MediaPreviewer = ({ media }) => {
    const { type, url } = media;
    const renderAudio = () => {
        if (type === 'AUDIO') return <audio controls src={url} />;
    };
    const renderVideo = () => {
        if (type === 'VIDEO') return (
            <Segment id="vid-cont" basic inverted style={{ position: 'relative', paddingTop: '56.25%' }}>
                <video
                    controls
                    src={url}
                    width='100%'
                    height='100%'
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0
                    }}
                />
            </Segment>
        );
    };
    const renderImage = () => {
        if (type === 'IMAGE') return <Image fluid src={url} />;
    };

    return (
        <Segment basic textAlign='center'>
            {renderAudio()}
            {renderVideo()}
            {renderImage()}
        </Segment>
    );
};

export default MediaPreviewer;