import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import 'react-toastify/dist/ReactToastify.min.css';
import React from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { Segment } from 'semantic-ui-react';
import { ToastContainer } from 'react-toastify';
import cookies from 'react-cookies';
import i18n from './i18n';
import Main from './Main';
import { store } from './store';
import { mediaStyle, MediaContextProvider } from './Media';

const App = () => {
  const lng = cookies.load('languagePref') || 'en';
  const isArabic = lng === 'ar';
  return (
    <>
      <style>{mediaStyle}</style>
      <MediaContextProvider>
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            <Segment basic style={{ margin: 0, padding: 0 }}>
              <Main />
              <ToastContainer rtl={isArabic} position={isArabic ? 'bottom-right' : 'bottom-left'} theme='dark' autoClose={3000} />
            </Segment>
          </I18nextProvider>
        </Provider>
      </MediaContextProvider>
    </>
  );
};

export default App;
