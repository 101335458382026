export default (user) => {
    if (!user) return '';
    if (user.ac) {
        if (user.ac.ADMINS_CONTROL_PANEL && user.ac.ADMINS_CONTROL_PANEL.READ) {
            return { ...user, isAdmin: true };
        }
        else if (Object.keys(user.ac).find(r => r.includes('ADMINS_CONTROL_PANEL'))) {
            return { ...user, isAdmin: true };
        }
    }
    return { ...user, isAdmin: false };

};