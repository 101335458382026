import _ from 'lodash';
import CustomStore from 'devextreme/data/custom_store';
import request from '../../api';
import { store } from '../../store';
import { adminGetAllBranches } from '../../actions';

const { dispatch, getState } = store;
const selectedBranchId = () => getState().adminBranches.selectedBranchId;

const generateParamsForRequest = (loadOptions, isMoreOptions) => {
    let params = '?';
    for (let i in loadOptions) {
        if (loadOptions[i])
            params += `${i}=${JSON.stringify(loadOptions[i])}&`;
    }
    if (isMoreOptions)
        return params;
    return params = params.slice(0, -1);
};

export const whatsAppMessagesDataGrid = new CustomStore({
    key: 'idMsgTemplate',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/message/schedule/all/branch/${selectedBranchId()}${params}`);
            return { data, totalCount, summary: [totalCount] };
        } catch (err) {
            return err;
        }
    },
    byKey: async (key) => await request.get(`/admin/message/schedule/${key}/details`),
    insert: async (values) => await request.post('/admin/message/schedule', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/message/schedule/update', { ...values, idMsgTemplate: key, OBBranchId: selectedBranchId() }),
    remove: async (key) => await request.delete('/admin/message/schedule', { data: { idMsgTemplate: key } })
});
whatsAppMessagesDataGrid.activate = async (values) => await request.post('/admin/message/schedule/activate', values);

export const categoriesDataGrid = new CustomStore({
    key: 'idCategory',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/categories/fullinfo/branch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/category', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/category/update', { ...values, categoryId: key, OBBranchId: selectedBranchId() }),
    remove: async (key) => await request.delete('/admin/category', { data: { categoryId: key } })
});

export const whatsAppContactsDataGrid = new CustomStore({
    key: 'idContact',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/contacts/all/branch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    // byKey: async (key) => await request.get(`/admin/message/schedule/${key}/details`),
    // insert: async (values) => await request.post('/admin/message/schedule', { ...values, OBBranchId: selectedBranchId() }),
    // update: async (key, values) => await request.post('/admin/message/schedule/update', { ...values, idMsgTemplate: key, OBBranchId: selectedBranchId() }),
    // remove: async (key) => await request.delete('/admin/message/schedule', { data: { idMsgTemplate: key } })
});


export const adminsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admins/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/user/new', _.omit(values, 'code')),
    update: async (key, values) => await request.post('/admin/user/update', { ..._.omit(values, 'code'), userId: key }),
    remove: async (key) => await request.delete('/user', { data: { userId: key } })
});

export const employeesDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { users: data, allCount: totalCount } } = await request.get(`/users/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/user/new', _.omit(values, 'code')),
    update: async (key, values) => await request.post('/admin/user/update', { ..._.omit(values, 'code'), userId: key }),
    remove: async (key) => await request.delete('/user', { data: { userId: key } })
});

export const rolesDataGrid = new CustomStore({
    key: 'roleId',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/access_control/roles/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/access_control/roles/new', values),
    update: async (key, values) => await request.post('/access_control/roles/update', { ...values, roleId: key }),
    remove: async (key) => await request.delete('/access_control/role', { data: { roleId: key } })
});

export const roleResourcesDataGrid = (roleId) => new CustomStore({
    key: 'id',
    load: async () => {
        try {
            const { data } = await request.get(`/access_control/permissions/resources/fullinfo/role/${roleId}`);
            return { data, totalCount: Object.keys(data).length };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/access_control/role/resource', { resourceId: values.resource, roleId }),
    remove: async (key) => await request.delete('/access_control/role/resource', { data: { resourceId: key, roleId } })
});
roleResourcesDataGrid.reset = async (roleId, resources) => await request.post('/access_control/role/resources/reset', { roleId, resources });

export const branchesDataGrid = new CustomStore({
    key: 'idOBBranch',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/obbranch/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => {
        await request.post('/admin/obbranch/new', values);
        adminGetAllBranches()(dispatch, getState);
    },
    update: async (key, values) => {
        await request.post('/admin/obbranch/update', { ...values, OBBranchId: key });
        adminGetAllBranches()(dispatch, getState);
    },
    remove: async (key) => {
        await request.delete('/admin/obbranch', { data: { OBBranchId: key } });
        adminGetAllBranches()(dispatch, getState);
    }
});
branchesDataGrid.activate = async (values) => await request.post('/admin/obbranch/activate', values);


export const finCurrenciesDataGrid = (equivalentAt) => new CustomStore({
    key: 'idCurrency',
    load: async (loadOptions) => {
        try {
            let params = generateParamsForRequest(loadOptions, true);
            if (equivalentAt)
                params += `equivalentAt=${equivalentAt}&`;
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/currencies/obbranch/${selectedBranchId()}${params}`);
            return { data: data.map(c => ({ ...c, parity: (1 / c.equivalent) })), totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/currency', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/currency/update', { ...values, currencyId: key }),
    remove: async (key) => await request.delete('/admin/aw/currency', { data: { currencyId: key } })
});

export const finCurrenciesEquivalentsDataGrid = new CustomStore({
    key: 'idTransform',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/currencies/transforms/obbranch/${selectedBranchId()}${params}`);
            return { data: data.map(c => ({ ...c, parity: (1 / c.equivalent) })), totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/currency/transform', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/currency/transform/update', { ...values, transformId: key }),
    remove: async (key) => await request.delete('/admin/aw/currency/transform', { data: { transformId: key } })
});

export const finMatFamiliesDataGrid = new CustomStore({
    key: 'idFamily',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/families/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/family', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/family/update', { ...values, familyId: key }),
    remove: async (key) => await request.delete('/admin/aw/family', { data: { familyId: key } })
});

export const finMatQualitiesDataGrid = new CustomStore({
    key: 'idQuality',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/qualities/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/quality', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/quality/update', { ...values, qualityId: key }),
    remove: async (key) => await request.delete('/admin/aw/quality', { data: { qualityId: key } })
});

export const finMatApplicationsDataGrid = new CustomStore({
    key: 'idApplication',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/applications/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/application', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/application/update', { ...values, applicationId: key }),
    remove: async (key) => await request.delete('/admin/aw/application', { data: { applicationId: key } })
});

export const finMatBrandsDataGrid = new CustomStore({
    key: 'idBrand',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/brands/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/brand', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/brand/update', { ...values, brandId: key }),
    remove: async (key) => await request.delete('/admin/aw/brand', { data: { brandId: key } })
});

export const finMaterialsDataGrid = new CustomStore({
    key: 'idMaterial',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/materials/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount, summary: [totalCount] };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/material', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/material/update', { ...values, materialId: key }),
    remove: async (key) => await request.delete('/admin/aw/material', { data: { materialId: key } })
});

export const finMaterialUnitsDataGrid = (materialId) => new CustomStore({
    key: 'idUnit',
    load: async (loadOptions) => {
        try {
            let params = generateParamsForRequest(loadOptions, true);
            if (materialId)
                params += `materialId=${materialId}&`;
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/units${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/unit', { ...values }),
    update: async (key, values) => await request.post('/admin/aw/unit/update', { ...values, unitId: key }),
    remove: async (key) => await request.delete('/admin/aw/unit', { data: { unitId: key } })
});


export const finCustomersSuppliersDataGrid = (type) => new CustomStore({
    key: 'idCustomer',
    load: async (loadOptions) => {
        console.log(loadOptions);
        try {
            if (type)
                loadOptions = { ...loadOptions, filter: loadOptions.filter ? [['type', '=', type], 'and', loadOptions.filter] : ['type', '=', type] };
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/customers/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount, summary: [totalCount] };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/customer', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/customer/update', { ...values, customerId: key }),
    remove: async (key) => await request.delete('/admin/aw/customer', { data: { customerId: key } })
});

export const finOffersDataGrid = new CustomStore({
    key: 'idOffer',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/offers/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/offer', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/offer/update', { ...values, offerId: key }),
    remove: async (key) => await request.delete('/admin/aw/offer', { data: { offerId: key } })
});
finOffersDataGrid.sendMessageToCustomers = async (values) => await request.post('/admin/aw/offer/message/consumers', { ...values, OBBranchId: selectedBranchId() });
finOffersDataGrid.sendStock = async (values) => await request.post('/admin/aw/offer/materials/message/consumers', { ...values, OBBranchId: selectedBranchId() });
finOffersDataGrid.getReceivers = async (type, mat, ids, cats, filter) => await request.get(`/admin/aw/offer/message/${type}/contacts/obbranch/${selectedBranchId()}`, {
    params: {
        customersIds: ids.length ? JSON.stringify(ids) : null,
        customersCategoriesIds: cats.length ? JSON.stringify(cats) : null,
        materialsIds: JSON.stringify([mat]),
        filter: filter ? JSON.stringify(filter) : undefined
    }
});

export const finFinalOffersDataGrid = new CustomStore({
    key: 'idOffer',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/offers/sales/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const finRequestOfferDataGrid = new CustomStore({
    key: 'idOfferRequest',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/offers/requests/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/offer/request', { ...values, OBBranchId: selectedBranchId() }),
});
finRequestOfferDataGrid.sendMessageToSuppliers = async (values) => await request.post('/admin/aw/offer/message/suppliers', { ...values, OBBranchId: selectedBranchId() });


export const finShippingCostsDataGrid = new CustomStore({
    key: 'idShippingCost',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/shipping/costs/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/shipping/cost', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/shipping/cost/update', { ...values, shippingCostId: key }),
    remove: async (key) => await request.delete('/admin/aw/shipping/cost', { data: { shippingCostId: key } })
});

export const regionsDataGrid = new CustomStore({
    key: 'regionId',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/regions/fullinfo/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/region', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/region/update', { ...values, regionId: key }),
    remove: async (key) => await request.delete('/admin/region', { data: { regionId: key } })
});

export const countriesDataGrid = new CustomStore({
    key: 'countryId',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/countries/fullinfo/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/country', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/country/update', { ...values, countryId: key }),
    remove: async (key) => await request.delete('/admin/country', { data: { countryId: key } })
});

export const citiesDataGrid = (countryId) => new CustomStore({
    key: 'cityId',
    load: async (loadOptions) => {
        try {
            let params = generateParamsForRequest(loadOptions, true);
            if (countryId)
                params += `countryId=${countryId}&`;
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/cities/fullinfo/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/city', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/city/update', { ...values, cityId: key }),
    remove: async (key) => await request.delete('/admin/city', { data: { cityId: key } })
});


export const branchSettingsDataGrid = new CustomStore({
    key: 'idSetting',
    load: async (loadOptions) => {
        try {
            let params = generateParamsForRequest(loadOptions, true);
            params += `OBBranchId=${selectedBranchId()}&`;
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/settings/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/setting', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/setting/update', { ...values, settingId: key }),
    remove: async (key) => await request.delete('/admin/setting', { data: { settingId: key } })
});

export const generalSettingsDataGrid = new CustomStore({
    key: 'idSetting',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/settings/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/setting', values),
    update: async (key, values) => await request.post('/admin/setting/update', { ...values, settingId: key }),
    remove: async (key) => await request.delete('/admin/setting', { data: { settingId: key } })
});

export const finPeriodsDataGrid = new CustomStore({
    key: 'financialPeriodId',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/financial/periods/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/financial/period', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/financial/period/update', { ...values, financialPeriodId: key }),
    remove: async (key) => await request.delete('/admin/aw/financial/period', { data: { financialPeriodId: key } })
});

export const finAccountsDataGrid = new CustomStore({
    key: 'accountId',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/financial/accounts/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/financial/account', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/financial/account/update', {
        ...values, accountId: key,
        mainAccountId: values.mainAccountId === undefined ? undefined : values.mainAccountId === null ? 0 : values.mainAccountId,
        defaultCostCenterId: values.defaultCostCenterId === undefined ? undefined : values.defaultCostCenterId === null ? 0 : values.defaultCostCenterId,
        estimatedBalance: values.estimatedBalance === undefined ? undefined : values.estimatedBalance === null ? 0 : values.estimatedBalance
    }),
    remove: async (key) => await request.delete('/admin/aw/financial/account', { data: { accountId: key } })
});

export const finCostCentersDataGrid = new CustomStore({
    key: 'costCenterId',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/financial/costs_centers/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/financial/cost_center', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/financial/cost_center/update', { ...values, costCenterId: key }),
    remove: async (key) => await request.delete('/admin/aw/financial/cost_center', { data: { costCenterId: key } })
});

export const finEntriesDataGrid = new CustomStore({
    key: 'entryId',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/financial/entries/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/financial/entry', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/financial/entry/update', { ...values, entryId: key }),
    remove: async (key) => await request.delete('/admin/aw/financial/entry', { data: { entryId: key } })
});
finEntriesDataGrid.getRecords = async (entryId) => await request.get(`/admin/aw/financial/entry/${entryId}/records`);


export const finEntryTypesDataGrid = new CustomStore({
    key: 'entryTypeId',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/financial/entries_types/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/financial/entry_type', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/financial/entry_type/update', { ...values, entryTypeId: key }),
    remove: async (key) => await request.delete('/admin/aw/financial/entry_type', { data: { entryTypeId: key } })
});

export const finWarehousesDataGrid = new CustomStore({
    key: 'storeId',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/financial/stores/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/financial/store', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/financial/store/update', { ...values, storeId: key, mainStoreId: values.mainStoreId === undefined ? undefined : values.mainStoreId === null ? 0 : values.mainStoreId }),
    remove: async (key) => await request.delete('/admin/aw/financial/store', { data: { storeId: key } })
});

export const finBillTypesDataGrid = new CustomStore({
    key: 'billTypeId',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/financial/bills_types/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/financial/bill_type', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/financial/bill_type/update', { ...values, billTypeId: key }),
    remove: async (key) => await request.delete('/admin/aw/financial/bill_type', { data: { billTypeId: key } })
});

export const finBillsDataGrid = new CustomStore({
    key: 'billId',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/financial/bills/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/financial/bill', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/financial/bill/update', { ...values, billId: key }),
    remove: async (key) => await request.delete('/admin/aw/financial/bill', { data: { billId: key } })
});
finBillsDataGrid.getRecords = async (billId) => await request.get(`/admin/aw/financial/bill/${billId}/records`);
finBillsDataGrid.getAdditionsDiscountsRecords = async (billId) => await request.get(`/admin/aw/financial/bill/${billId}/adds_discounts`);

export const finProformaInvoiceDataGrid = new CustomStore({
    key: 'proformaInvoiceId',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/financial/proforma_invoice/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/financial/proforma_invoice', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/financial/proforma_invoice/update', { ...values, proformaInvoiceId: key }),
    remove: async (key) => await request.delete('/admin/aw/financial/proforma_invoice', { data: { proformaInvoiceId: key } })
});
finProformaInvoiceDataGrid.getRecords = async (proformaInvoiceId) => await request.get(`/admin/aw/financial/proforma_invoice/${proformaInvoiceId}/records`);
finProformaInvoiceDataGrid.getAdditionsDiscountsRecords = async (proformaInvoiceId) => await request.get(`/admin/aw/financial/proforma_invoice/${proformaInvoiceId}/adds_discounts`);


export const finWarehouseTransporationsDataGrid = new CustomStore({
    key: 'transportationId',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/aw/financial/transportations/obbranch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/aw/financial/transportation', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/aw/financial/transportation/update', { ...values, transportationId: key }),
    remove: async (key) => await request.delete('/admin/aw/financial/transportation', { data: { transportationId: key } })
});
finWarehouseTransporationsDataGrid.getRecords = async (transportationId) => await request.get(`/admin/aw/financial/transportation/${transportationId}/records`);

export const finSalesDealsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/sale-deals/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/sale-deal', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/sale-deal/update', { ...values, id: key }),
    remove: async (key) => await request.delete('/admin/sale-deal', { data: { id: key } })
});


export const finSalesContactsDataGrid = new CustomStore({
    key: 'idContact',
    load: async (loadOptions) => {
        try {
            loadOptions = { ...loadOptions, filter: ['type', '=', "SALE"] };
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/contacts/all/branch/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/contact', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/contact/update', { ...values, contactId: key, OBBranchId: selectedBranchId() }),
    remove: async (key) => await request.delete('/admin/contacts', { data: { contactsIds: [key] } })
});

export const finSalesReservationsDataGrid = new CustomStore({
    key: 'id',
    load: async (loadOptions) => {
        try {
            const params = generateParamsForRequest(loadOptions);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/sale-reserve/${selectedBranchId()}${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
    insert: async (values) => await request.post('/admin/sale-reserve', { ...values, OBBranchId: selectedBranchId() }),
    update: async (key, values) => await request.post('/admin/sale-reserve', { ...values, id: key }),
    remove: async (key) => await request.delete('/admin/sale-reserve', { data: { id: key } })
});