// AUTH TYPES
export const SIGNIN_INFO_CHANGED = 'SIGNIN_INFO_CHANGED';
export const SIGNIN_START = 'SIGNIN_START';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_FAIL = 'SIGNIN_FAIL';
export const SIGNOUT = 'SIGNOUT';
export const SIGNIN_FINISH = 'SIGNIN_FINISH';
export const CHECK_AUTH = 'CHECK_AUTH';

export const SIGNUP_INFO_CHANGED = 'SIGNUP_INFO_CHANGED';
export const SIGNUP_OPERATION_START = 'SIGNUP_OPERATION_START';
export const SIGNUP_OPERATION_FAIL = 'SIGNUP_OPERATION_FAIL';
export const SIGNUP_OPERATION_FINISH = 'SIGNUP_OPERATION_FINISH';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';

export const PROFILE_INFO_CHANGED = 'PROFILE_INFO_CHANGED';
export const PROFILE_OPERATION_START = 'PROFILE_OPERATION_START';
export const PROFILE_OPERATION_FAIL = 'PROFILE_OPERATION_FAIL';
export const PROFILE_OPERATION_FINISH = 'PROFILE_OPERATION_FINISH';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';

export const FORGOTPASSWORD_INFO_CHANGED = 'FORGOTPASSWORD_INFO_CHANGED';
export const POST_FORGOTPASSWORD_START = 'POST_FORGOTPASSWORD_START';
export const POST_FORGOTPASSWORD_FAIL = 'POST_FORGOTPASSWORD_FAIL';
export const POST_FORGOTPASSWORD = 'POST_FORGOTPASSWORD';

export const POST_ACTIVATION_CODE_START = 'POST_ACTIVATION_CODE_START';
export const POST_ACTIVATION_CODE_FAIL = 'POST_ACTIVATION_CODE_FAIL';
export const POST_ACTIVATION_CODE = 'POST_ACTIVATION_CODE';
export const RESEND_ACTIVATION_CODE_START = 'RESEND_ACTIVATION_CODE_START';
export const RESEND_ACTIVATION_CODE_FAIL = 'RESEND_ACTIVATION_CODE_FAIL';
export const RESEND_ACTIVATION_CODE = 'RESEND_ACTIVATION_CODE';
export const ACTIVATION_CODE_INFO_CHANGED = 'ACTIVATION_CODE_INFO_CHANGED';

export const CONTACTUS_INFO_CHANGED = 'CONTACTUS_INFO_CHANGED';
export const POST_CONTACTUS_START = 'POST_CONTACTUS_START';
export const POST_CONTACTUS_FAIL = 'POST_CONTACTUS_FAIL';
export const POST_CONTACTUS = 'POST_CONTACTUS';

export const COUNTRIES_INFO_CHANGED = 'COUNTRIES_INFO_CHANGED';
export const COUNTRIES_OPERATION_START = 'COUNTRIES_OPERATION_START';
export const COUNTRIES_OPERATION_FAIL = 'COUNTRIES_OPERATION_FAIL';
export const COUNTRIES_OPERATION_FINISH = 'COUNTRIES_OPERATION_FINISH';
export const COUNTRIES_SUCCESS = 'COUNTRIES_SUCCESS';
export const CITIES_OPERATION_FAIL = 'CITIES_OPERATION_FAIL';
export const CITIES_OPERATION_FINISH = 'CITIES_OPERATION_FINISH';
export const CITIES_OPERATION_START = 'CITIES_OPERATION_START';
export const CITIES_SUCCESS = 'CITIES_SUCCESS';

export const RESET_PASSWORD_OPERATION_START = 'RESET_PASSWORD_OPERATION_START';
export const RESET_PASSWORD_OPERATION_FAIL = 'RESET_PASSWORD_OPERATION_FAIL';
export const RESET_PASSWORD_OPERATION_FINISH = 'RESET_PASSWORD_OPERATION_FINISH';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';



// ADMIN TYPES
export const ADMIN_SELECT_PORTAL = 'ADMIN_SELECT_PORTAL';

export const WHATS_APP_CONTACNTS_OPERATION_START = 'WHATS_APP_CONTACNTS_OPERATION_START';
export const WHATS_APP_CONTACNTS_OPERATION_FAIL = 'WHATS_APP_CONTACNTS_OPERATION_FAIL';
export const WHATS_APP_CONTACNTS_OPERATION_FINISH = 'WHATS_APP_CONTACNTS_OPERATION_FINISH';
export const WHATS_APP_CONTACNTS_SUCCESS = 'WHATS_APP_CONTACNTS_SUCCESS';

export const ADMIN_GET_BRANCHES_OPERATION_START = 'ADMIN_GET_BRANCHES_OPERATION_START';
export const ADMIN_GET_BRANCHES_OPERATION_FAIL = 'ADMIN_GET_BRANCHES_OPERATION_FAIL';
export const ADMIN_GET_BRANCHES_OPERATION_FINISH = 'ADMIN_GET_BRANCHES_OPERATION_FINISH';
export const ADMIN_GET_BRANCHES_SUCCESS = 'ADMIN_GET_BRANCHES_SUCCESS';
export const ADMIN_SELECT_BRANCH = 'ADMIN_SELECT_BRANCH';
export const ADMIN_CLEAR_ALL_BRANCHES_DATA = 'ADMIN_CLEAR_ALL_BRANCHES_DATA';


export const ADMIN_GET_MAYTAPI_STATUS_OPERATION_START = 'ADMIN_GET_MAYTAPI_STATUS_OPERATION_START';
export const ADMIN_GET_MAYTAPI_STATUS_OPERATION_FAIL = 'ADMIN_GET_MAYTAPI_STATUS_OPERATION_FAIL';
export const ADMIN_GET_MAYTAPI_STATUS_OPERATION_FINISH = 'ADMIN_GET_MAYTAPI_STATUS_OPERATION_FINISH';
export const ADMIN_GET_MAYTAPI_STATUS_SUCCESS = 'ADMIN_GET_MAYTAPI_STATUS_SUCCESS';

// USER TYPES
