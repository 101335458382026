import i18n from 'i18next';
import cookies from 'react-cookies';
import {
    ADMIN_GET_BRANCHES_OPERATION_START,
    ADMIN_GET_BRANCHES_OPERATION_FAIL,
    ADMIN_GET_BRANCHES_OPERATION_FINISH,
    ADMIN_GET_BRANCHES_SUCCESS,
    ADMIN_SELECT_BRANCH,
    ADMIN_CLEAR_ALL_BRANCHES_DATA
} from '../types';
import request from '../../api';
import { adminGetMaytapiStatus } from '..';

export const adminSelectBranch = (branchId) => async (dispatch, getState) => {
    const selectedBranchCookie = cookies.load('branchPref');
    if (selectedBranchCookie) cookies.remove('branchPref');
    cookies.save('branchPref', branchId, {
        path: '/',
        expires: new Date().setDate(Date.now() + 1000 * 60 * 60 * 24 * 14),
        maxAge: 60 * 60 * 24 * 365
    });
    await dispatch({ type: ADMIN_SELECT_BRANCH, payload: branchId });
    await adminGetMaytapiStatus()(dispatch, getState);
};

export const adminClearAllBranchesData = () => {
    return { type: ADMIN_CLEAR_ALL_BRANCHES_DATA };
};

export const adminGetAllBranches = () => async dispatch => {
    dispatch({ type: ADMIN_GET_BRANCHES_OPERATION_START });
    try {
        const { data: { data } } = await request.get('/admin/obbranch/fullinfo');
        dispatch({ type: ADMIN_GET_BRANCHES_SUCCESS, payload: data || [] });
        return data || [];
    } catch (err) {
        if (err.response) dispatch({ type: ADMIN_GET_BRANCHES_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: ADMIN_GET_BRANCHES_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: ADMIN_GET_BRANCHES_OPERATION_FINISH });
    }
};