import React from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

const ErrorSegment = ({ t, text, icon }) => {
    return (
        <Segment basic disabled placeholder>
            <Header icon>
                <Icon name={icon || 'times circle outline'} color='red' />
                {text || t('ErrorBody')}
            </Header>
        </Segment>
    );
};

export default withTranslation()(ErrorSegment);