import i18n from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
// import { locale } from 'expo-localization';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import cookies from 'react-cookies';
import languageEN from './locates/en.json';
import languageAR from './locates/ar.json';
i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: languageEN,
            ar: languageAR
        },
        /* default language when load the website in browser */
        lng: cookies.load('languagePref') || i18n.language === 'ar' ? 'ar' : 'en',
        /* When react i18next not finding any language to as default in borwser */
        fallbackLng: "en",
        /* debugger For Development environment */
        //debug: true,
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: ".",
        interpolation: {
            escapeValue: false,
            formatSeparator: ","
        },
        react: {
            useSuspense: true
            // bindI18n: 'languageChanged loaded',
            // bindStore: 'added removed',
            // nsMode: 'default'
        }
    })

export default i18n;