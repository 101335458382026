import React, { useState, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import i18next from 'i18next';

const ScrollToTop = () => {
    const [vis, setVis] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 100) setVis(true);
        else setVis(false);
    };

    const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    useEffect(() => {
        document.addEventListener('scroll', toggleVisibility);
        return () => document.removeEventListener('scroll', () => { });
    }, []);

    if (!vis) return null;
    return (
        <div onClick={scrollToTop} style={i18next.language === 'en' ? style.ltr : style.rtl}>
            <Icon name='arrow up' color='black' size='large' inverted circular />
        </div>
    );
};

const style = {
    rtl: {
        position: 'fixed',
        bottom: '3vh',
        zIndex: 1500,
        cursor: 'pointer',
        left: '2vw'
    },
    ltr: {
        position: 'fixed',
        bottom: '3vh',
        zIndex: 1500,
        cursor: 'pointer',
        right: '2vw'
    }
}
export default ScrollToTop;