import React, { useEffect } from 'react';
import { Card, Header, Icon, Segment } from 'semantic-ui-react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { adminGetAllBranches, adminSelectBranch } from '../../actions';
import { withTranslation } from 'react-i18next';
import { ErrorSegment } from '..';

const BranchSelector = (props) => {
    const { t, onClose, error, loading, branches,
        selectedBranchId, adminGetAllBranches, adminSelectBranch } = props;
    const isArabic = i18next.language === 'ar';

    useEffect(() => {
        adminGetAllBranches();

    }, [adminGetAllBranches]);

    const onBranchSelect = (id) => {
        adminSelectBranch(id);
        if (onClose)
            onClose();
    };

    if (loading) return <Segment basic padded='very' loading={loading} />;
    if (error) return <ErrorSegment />;
    if (!branches.length) return <ErrorSegment text={t('Empty')} />;

    return (
        <Segment basic>
            <Header as='h3' content={t('SelectBranch')} />
            <Card.Group itemsPerRow='3' stackable>
                {
                    branches.map((b, indx) => <Card
                        key={b.idOBBranch}
                        centered
                        link
                        header={`${indx + 1}) ${isArabic ? b.nameAr : b.nameEn}`}
                        color={b.idOBBranch === selectedBranchId ? 'green' : 'black'}
                        onClick={() => onBranchSelect(b.idOBBranch)}
                        extra={b.idOBBranch === selectedBranchId ? <Icon name='check circle' color='green' size='big' /> : null}
                    />)
                }
            </Card.Group>
        </Segment>
    );
};

const mapStateTopProps = ({ adminBranches }) => {
    const { branches, error, loading, selectedBranchId } = adminBranches;
    return { branches, error, loading, selectedBranchId };
};

export default withTranslation()(connect(mapStateTopProps, { adminGetAllBranches, adminSelectBranch })(BranchSelector));