import React from 'react';
import { Message, Popup, Segment } from 'semantic-ui-react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { BranchSelector } from '..';

const NoBranch = ({ selectedBranchId, branches, user, t }) => {
    const branchMetaData = branches.find(b => b.idOBBranch === selectedBranchId);
    const index = branches.findIndex(b => b.idOBBranch === selectedBranchId);

    const isArabic = i18next.language === 'ar';
    return (
        <Popup
            on='click'
            flowing
            hoverable
            trigger={
                <Segment attached inverted style={{ border: 'none', marginLeft: 10, marginRight: 10, padding: 0 }}>
                    {!branchMetaData && user.isAdmin ?
                        <Message
                            error
                            icon='building'
                            header={t('SelectBranch')}
                            content={`${t('Dashboard')} => ${t('Branches')}`}
                            style={{ cursor: 'pointer' }}
                        />
                        : null}

                    {branchMetaData && user.isAdmin ?
                        <Message
                            info
                            size='mini'
                            icon='building'
                            header={`${t('Branch')} (${index + 1}) : ${isArabic ? branchMetaData.nameAr : branchMetaData.nameEn} [${branchMetaData.isActive ? t('Active') : t('Inactive')}]`}
                            style={{ cursor: 'pointer', padding: 3.5 }}
                        />
                        : null}
                </Segment>
            }
        >
            <BranchSelector />
        </Popup>

    );
};

const mapStateTopProps = ({ auth, adminBranches }) => {
    const { user } = auth;
    const { selectedBranchId, branches } = adminBranches;
    return { user, selectedBranchId, branches };
};

export default withTranslation()(connect(mapStateTopProps, {})(NoBranch));