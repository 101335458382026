import cookies from 'react-cookies';
import {
    ADMIN_SELECT_PORTAL,
} from '../types';

export const adminSelectPortal = (portal) => {
    cookies.save('portalPref', portal, {
        path: '/',
        expires: new Date().setDate(Date.now() + 1000 * 60 * 60 * 24 * 14),
        maxAge: 60 * 60 * 24 * 365
    });
    return { type: ADMIN_SELECT_PORTAL, payload: portal };
};
