import React, { useState } from 'react';
import { Grid, GridColumn, Icon, Segment, Image } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { adminGetMaytapiStatus } from '../../actions';
import { ErrorSegment } from '..';
import request from '../../api';


const MaytapiStatus = (props) => {
    const { t, user, selectedBranchId, status, loading, error, adminGetMaytapiStatus } = props;
    const [loadingQr, setLoadingQr] = useState(false);
    const [errorQr, setErrorQr] = useState('');
    const [qr, setQr] = useState(null);

    const generateMaytapiQr = async () => {
        setLoadingQr(true);
        try {
            const res = await request.get(`/admin/obbranch/${selectedBranchId}/whatsapp/msging/qr`, { responseType: 'arraybuffer' });
            const blob = new Blob([res.data], { type: res.headers['content-type'] });
            const image = URL.createObjectURL(blob);
            setQr(image || null);
        } catch (err) {
            setErrorQr(err);
        } finally {
            setLoadingQr(false);
        }
    };

    if (!user || !selectedBranchId) return null;
    if (loading) return <Segment size='mini' basic loading={loading} padded />;

    const renderStatusString = () => {
        switch (status) {
            case 'CONNECTED': return t('Connected');
            case 'NOT_CONNECTED': return t('NotConnected');
            case 'NEED_SCAN': return t('NeedScan');
            case 'UNKNOWN': return t('Unknown');
            default: return null;
        }
    };

    const renderQr = () => {
        if (!error && status === 'NEED_SCAN') return (
            <GridColumn>
                <Icon name='qrcode' link onClick={generateMaytapiQr} /> {t('Scan')}
            </GridColumn>
        );
    };

    const renderStatus = () => {
        if (!error) return (
            <GridColumn>
                <Icon name='mobile alternate' /> {renderStatusString()}
            </GridColumn>
        );

        return (
            <GridColumn>
                <Icon name='times' /> {t('ErrorBody')}
            </GridColumn>
        );
    };

    const renderQrCode = () => {
        if (loadingQr) return <Segment basic padded placeholder loading={loadingQr} />;
        if (errorQr) return <ErrorSegment />;
        if (!qr) return null;
        return (
            <Segment basic>
                <Image src={qr} centered />
            </Segment>
        );
    };

    return (
        <Segment inverted color={error ? 'red' : status === 'CONNECTED' ? 'green' : 'red'}>
            <Grid columns='equal' textAlign='center' verticalAlign='middle' relaxed='very'>

                {renderStatus()}
                {renderQr()}

                <GridColumn>
                    <Icon name='refresh' link onClick={adminGetMaytapiStatus} /> {t('Refresh')}
                </GridColumn>

            </Grid>

            {renderQrCode()}
        </Segment>
    );
};

const mapStateToProps = ({ auth, adminBranches, adminMaytapi }) => {
    const { user } = auth;
    const { selectedBranchId } = adminBranches;
    const { status, loading, error } = adminMaytapi;
    return { user, selectedBranchId, status, loading, error };
};

export default withTranslation()(connect(mapStateToProps, { adminGetMaytapiStatus })(MaytapiStatus));