import React from 'react';
import { Card, Header, Icon, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { adminSelectPortal } from '../../actions';

const PortalSelector = ({ t, adminSelectPortal, onClose }) => {
    const selectedPortal = useSelector(({ portal }) => portal.selectedPortal);
    const Portals = [
        { text: t('General'), value: 'GENERAL', icon: 'th large' },
        { text: t('HR'), value: 'HR', icon: 'users' },
        { text: t('Financial'), value: 'FINANCIAL', icon: 'dollar' },
        { text: t('Marketing'), value: 'MARKETING', icon: 'audio description' },
        { text: t('Sales'), value: 'SALES', icon: 'male' },
    ];
    return (
        <Segment basic textAlign='center'>
            <Card.Group itemsPerRow={2}>
                {
                    Portals.map((p, indx) => <Card
                        as={Link}
                        to='/'
                        raised
                        link
                        key={indx}
                        onClick={() => {
                            adminSelectPortal(p.value);
                            onClose();
                        }}
                        color={p.value === selectedPortal ? 'blue' : 'black'}
                    >
                        <Card.Content>
                            <Icon link name={p.icon} size='huge' color={p.value === selectedPortal ? 'blue' : 'red'} />
                        </Card.Content>
                        <Card.Content extra>
                            <Header as='h3'
                                content={p.text}
                                color={p.value === selectedPortal ? 'blue' : 'black'}
                            />
                        </Card.Content>
                    </Card>)
                }
            </Card.Group>
        </Segment>
    );
};

export default withTranslation()(connect(null, { adminSelectPortal })(PortalSelector));
