import React, { useState } from 'react';
import { Image, Icon } from 'semantic-ui-react';
import { ImagesUploader } from '..';

const ImagesUploaderTemplate = ({ data }) => {
    const [images, setImages] = useState(data.value || []);

    const handleImages = (imgs) => {
        setImages(imgs);
        if (data.setValue)
            data.setValue(imgs);
    };

    return (
        <>
            <ImagesUploader data={{ value: images, setValue: handleImages }} />
            <Image.Group size='small' style={{ textAlign: 'center' }}>
                {
                    images.map((img, indx) => <div key={indx} style={{ position: 'relative', display: 'inline-block' }}>
                        <Image centered src={img} style={{ height: '20vh' }} />
                        <Icon link name='delete' color='red' circular inverted style={{ position: "absolute", left: 1, margin: 5, fontSize: 15 }} onClick={() => handleImages(images.filter(i => i !== img))} />
                    </div>)
                }
            </Image.Group>
        </>
    );
};

export default ImagesUploaderTemplate;