import React, { createRef } from 'react';
import { withTranslation } from 'react-i18next';
import DataGrid, { Column, Editing, RequiredRule, Lookup } from 'devextreme-react/ui/data-grid';

const IndividualsSelector = ({ t, data, individuals, individualsType }) => {
    const gridRef = createRef();
    const handleDataStore = (DS) => data.setValue(DS);
    const getFilteredIndividuals = () => {
        return {
            store: individuals,
            filter: ['type', '=', individualsType],
            paginate: true,
            pageSize: 50,
        };
    };

    return (
        <DataGrid
            ref={gridRef}
            dataSource={data.value}
            showBorders
            showColumnLines
            columnHidingEnabled
            wordWrapEnabled
            columnAutoWidth
            onRowUpdated={() => handleDataStore(gridRef.current._instance.getDataSource()._items)}
            onRowInserted={() => handleDataStore(gridRef.current._instance.getDataSource()._items)}
            onRowRemoved={() => handleDataStore(gridRef.current._instance.getDataSource()._items)}
        >
            <Editing
                mode='row'
                useIcons
                allowUpdating
                allowAdding
                allowDeleting
                refreshMode='reshape'
            />
            <Column dataField='idCustomer' caption={t('Individual')} alignment='center'>
                <Lookup
                    dataSource={getFilteredIndividuals}
                    displayExpr='fullName'
                    valueExpr='idCustomer'
                />
                <RequiredRule />
            </Column>
        </DataGrid>
    );
};

export default withTranslation()(IndividualsSelector);
