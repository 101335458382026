import i18n from 'i18next';
import {
    SIGNIN_INFO_CHANGED,
    SIGNIN_SUCCESS,
    SIGNIN_START,
    SIGNIN_FAIL,
    SIGNIN_FINISH,
    SIGNOUT,
    CHECK_AUTH,
} from '../../actions/types';
import checkRole from '../../helpers/checkRole';

const INITIAL_STATE = {
    error: '',
    user: null,
    loading: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SIGNIN_INFO_CHANGED:
            return { ...state, [action.payload.props]: action.payload.value };
        case SIGNIN_START:
            return { ...state, loading: true };
        case SIGNIN_SUCCESS:
            return { ...state, user: checkRole(action.payload), error: '', userName: '', password: '' };
        case SIGNIN_FAIL:
            return { ...state, error: i18n.t('SigninFail') };
        case SIGNIN_FINISH:
            return { ...state, loading: false };
        case CHECK_AUTH:
            return { ...state, user: checkRole(action.payload) };
        case SIGNOUT:
            return { ...state, user: "" };
        default:
            return state;
    }
};
