import {
    ADMIN_GET_BRANCHES_OPERATION_START,
    ADMIN_GET_BRANCHES_OPERATION_FAIL,
    ADMIN_GET_BRANCHES_OPERATION_FINISH,
    ADMIN_GET_BRANCHES_SUCCESS,
    ADMIN_SELECT_BRANCH,
    ADMIN_CLEAR_ALL_BRANCHES_DATA,
} from '../../actions/types';

const INITIAL_STATE = {
    error: '',
    loading: false,
    branches: [],
    selectedBranchId: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_GET_BRANCHES_OPERATION_START:
            return { ...state, loading: true };
        case ADMIN_GET_BRANCHES_OPERATION_FINISH:
            return { ...state, loading: false };
        case ADMIN_GET_BRANCHES_OPERATION_FAIL:
            return { ...state, error: { [action.payload.props]: action.payload.value } };
        case ADMIN_GET_BRANCHES_SUCCESS:
            return { ...state, branches: action.payload };
        case ADMIN_SELECT_BRANCH:
            return { ...state, selectedBranchId: action.payload };
        case ADMIN_CLEAR_ALL_BRANCHES_DATA:
            return { ...state, ...INITIAL_STATE, selectedBranchId: state.selectedBranchId };
        default:
            return state;
    }
};

