import i18next from 'i18next';
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Label, Segment, Form, Divider, Header } from 'semantic-ui-react';
import checkInputDir from '../../../../helpers/checkInputDir';
import getPlaceholders from './placeholders';

HTMLTextAreaElement.prototype.insertAtCaret = function (text) {
    text = text || '';
    if (document.selection) {
        // IE
        this.focus();
        var sel = document.selection.createRange();
        sel.text = text;
    } else if (this.selectionStart || this.selectionStart === 0) {
        // Others
        var startPos = this.selectionStart;
        var endPos = this.selectionEnd;
        this.value = this.value.substring(0, startPos) +
            text +
            this.value.substring(endPos, this.value.length);
        this.selectionStart = startPos + text.length;
        this.selectionEnd = startPos + text.length;
    } else {
        this.value += text;
    }
};

const MsgTemplates = (props) => {

    const { settingName, value, setValue, t } = props;
    const val = value ? JSON.parse(value) : {};
    const [msg, setMsg] = useState(val.contentEn || '');
    const [placeholders] = useState(getPlaceholders(settingName));
    const [mustSendPhotos, setMustSendPhotos] = useState(val.mustSendOfferImgs || false);
    const [mustSendTDS, setMustSendTDS] = useState(val.mustSendMaterialTDS || false);
    const textAreaId = `${settingName}textMsg`;

    const onMsgValueChange = (value) => {
        checkInputDir(textAreaId);
        setMsg(value);
        saveValue(value, mustSendPhotos, mustSendTDS);
    };

    const saveValue = (p_msg, p_photos, p_tds) => {
        const obj = {
            contentEn: p_msg,
            mustSendOfferImgs: p_photos,
            mustSendMaterialTDS: p_tds
        };

        return setValue(settingName, JSON.stringify(obj));
    };

    const addPlaceholderToMsg = (placeholder) => {
        const msgTextAreaRef = document.getElementById(textAreaId);
        msgTextAreaRef.insertAtCaret(`|^${placeholder}^| `);
        setMsg(msgTextAreaRef.value);
        msgTextAreaRef.focus();
        saveValue(msgTextAreaRef.value, mustSendPhotos, mustSendTDS);
    };

    useEffect(() => {
        checkInputDir(textAreaId);
        //eslint-disable-next-line
    }, []);

    return (
        <Segment>
            <Header as='h5' color='grey' content={t('Placeholders')} />
            <Label.Group size='small'>
                {
                    Object.values(placeholders).filter(pp => !pp.attachment).map((p, indx) => <Label key={indx} content={i18next.t(p.name)} circular color='teal' as='a' onClick={() => addPlaceholderToMsg(p.value)} />)
                }
            </Label.Group>
            <Divider />
            <Form style={{ dir: 'auto' }}>
                <Form.TextArea
                    id={textAreaId}
                    required
                    value={msg}
                    onChange={(e, { value }) => onMsgValueChange(value)}
                    style={{ minHeight: '35vh', dir: 'auto' }}
                />

                {placeholders.mustSendOfferImgs && <Form.Checkbox
                    label={t('mustSendOfferImgs')}
                    checked={mustSendPhotos}
                    onChange={(e, { checked }) => {
                        setMustSendPhotos(checked);
                        saveValue(msg, checked, mustSendTDS);
                    }}
                />}
                {placeholders.mustSendMaterialTDS && <Form.Checkbox
                    label={t('mustSendMaterialTDS')}
                    checked={mustSendTDS}
                    onChange={(e, { checked }) => {
                        setMustSendTDS(checked);
                        saveValue(msg, mustSendPhotos, checked);
                    }}
                />}
            </Form>
        </Segment>
    );
};

export default withTranslation()(MsgTemplates);