import React, { createRef } from 'react';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import DataGrid, { Column, Editing, RequiredRule, Lookup } from 'devextreme-react/ui/data-grid';

const CategoriesSelector = ({ t, data, categories }) => {
    const isArabic = i18next.language === 'ar';
    const gridRef = createRef();
    const handleDataStore = (DS) => data.setValue(DS);
    const getFilteredCategories = () => {
        return {
            store: categories,
            paginate: true,
            pageSize: 50,
        };
    };

    return (
        <DataGrid
            ref={gridRef}
            dataSource={data.value}
            showBorders
            showColumnLines
            columnHidingEnabled
            wordWrapEnabled
            columnAutoWidth
            onRowUpdated={() => handleDataStore(gridRef.current._instance.getDataSource()._items)}
            onRowInserted={() => handleDataStore(gridRef.current._instance.getDataSource()._items)}
            onRowRemoved={() => handleDataStore(gridRef.current._instance.getDataSource()._items)}
        >
            <Editing
                mode='row'
                useIcons
                allowUpdating
                allowAdding
                allowDeleting
                refreshMode='reshape'
            />
            <Column dataField='idCategory' caption={t('Category')} alignment='center'>
                <Lookup
                    dataSource={getFilteredCategories}
                    displayExpr={isArabic ? 'nameAr' : 'nameEn'}
                    valueExpr='idCategory'
                />
                <RequiredRule />
            </Column>
        </DataGrid>
    );
};

export default withTranslation()(CategoriesSelector);
