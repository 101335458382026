import i18n from 'i18next';
import {
    ADMIN_GET_MAYTAPI_STATUS_OPERATION_START,
    ADMIN_GET_MAYTAPI_STATUS_OPERATION_FAIL,
    ADMIN_GET_MAYTAPI_STATUS_OPERATION_FINISH,
    ADMIN_GET_MAYTAPI_STATUS_SUCCESS,
} from '../types';
import request from '../../api';

export const adminGetMaytapiStatus = () => async (dispatch, getState) => {
    dispatch({ type: ADMIN_GET_MAYTAPI_STATUS_OPERATION_START });
    const { selectedBranchId } = getState().adminBranches;
    try {
        const { data: { data } } = await request.get(`/admin/obbranch/${selectedBranchId}/whatsapp/msging/status`);
        dispatch({ type: ADMIN_GET_MAYTAPI_STATUS_SUCCESS, payload: data });
    } catch (err) {
        if (err.response) dispatch({ type: ADMIN_GET_MAYTAPI_STATUS_OPERATION_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: ADMIN_GET_MAYTAPI_STATUS_OPERATION_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: ADMIN_GET_MAYTAPI_STATUS_OPERATION_FINISH });
    }
};
