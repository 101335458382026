import {
    ADMIN_GET_MAYTAPI_STATUS_OPERATION_START,
    ADMIN_GET_MAYTAPI_STATUS_OPERATION_FAIL,
    ADMIN_GET_MAYTAPI_STATUS_OPERATION_FINISH,
    ADMIN_GET_MAYTAPI_STATUS_SUCCESS,
} from '../../actions/types';

const INITIAL_STATE = {
    error: '',
    loading: false,
    status: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_GET_MAYTAPI_STATUS_OPERATION_START:
            return { ...state, loading: true };
        case ADMIN_GET_MAYTAPI_STATUS_OPERATION_FINISH:
            return { ...state, loading: false };
        case ADMIN_GET_MAYTAPI_STATUS_OPERATION_FAIL:
            return { ...state, error: { [action.payload.props]: action.payload.value }, status: null };
        case ADMIN_GET_MAYTAPI_STATUS_SUCCESS:
            return { ...state, status: action.payload, error: '' };
        default:
            return state;
    }
};

