import React, { useState } from 'react';
import { Icon, Grid, GridColumn, Segment } from 'semantic-ui-react';
import { ReactMicGold } from '@hackingbeauty/react-mic-gold';
import request from '../../api';

const VoiceRecorder = ({ data }) => {
    const [recordState, setRecordState] = useState(false);
    const [audioData, setAudioData] = useState(null);
    const [loading, setLoading] = useState(false);

    const uploadVoiceFile = async () => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('voice', blobToFile(audioData.blob, 'audio.mp3'));
            const { data: { voiceUrl } } = await request.post('/voice/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    // 'converttomp3': true
                }
            });

            if (data.setValue)
                data.setValue([...data.value, voiceUrl]);

            remove();
        } catch (err) {
            remove();
        } finally {
            setLoading(false);
        }
    };

    const start = () => setRecordState(true);
    // const pause = () => setRecordState(RecordState.PAUSE);
    const stop = () => setRecordState(false);
    const remove = () => {
        setRecordState(false);
        setAudioData(null);
    }

    const onStop = (blob) => setAudioData(blob);

    const blobToFile = (theBlob, fileName) => {
        return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
    };

    if (loading) return <Segment basic loading={loading} padded />;

    return (
        <Segment basic textAlign='center'>
            {/* <AudioReactRecorder
                state={recordState}
                onStop={onStop}
                backgroundColor='white'
                canvasWidth='200'
                canvasHeight='20'
                type='audio/mp3'
            /> */}
            <Segment basic>
                <ReactMicGold
                    record={recordState}
                    onStop={onStop}
                    strokeColor="green"
                    mimeType="audio/mp3"
                    backgroundColor="rgba(200,200,200,.9)"
                    visualSetting="frequencyBars" // defaults -> "sinewave".  Other option is "frequencyBars"
                    className='sound-waves'       // provide css class name
                    onBlock={() => { }}       // optional - called if user selected "block" when prompted to allow microphone access (available in React-Mic-Gold)
                    bitRate={256000}          // defaults -> 128000 (128kbps).  React-Mic-Gold only.
                    sampleRate={96000}        // defaults -> 44100 (44.1 kHz).  It accepts values only in range: 22050 to 96000 (available in React-Mic-Gold)
                    timeSlice={3000}
                />
            </Segment>

            <Grid columns='2' stackable verticalAlign='middle' textAlign='center'>
                <GridColumn>
                    <audio
                        id='audio'
                        controls
                        src={audioData ? audioData.blobURL : null}
                    />
                </GridColumn>

                <GridColumn>

                    {!audioData && recordState === false && < Icon size='huge' link name='microphone' onClick={start} />}
                    {/* {!audioData && <Icon size='huge' link name='pause' onClick={pause} />} */}
                    {!audioData && recordState !== false && <Icon size='huge' color='green' link name='microphone slash' onClick={stop} />}
                    {audioData && <Icon size='huge' color='red' link name='trash' onClick={remove} />}
                    {audioData && <Icon size='huge' color='blue' link name='upload' onClick={uploadVoiceFile} />}

                </GridColumn>
            </Grid>
        </Segment>

    );
};


export default VoiceRecorder;