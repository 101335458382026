import React, { useState } from 'react';
import { FilesUploader } from '../..';

const UploadFileInput = (props) => {

    const { value, setValue, settingName } = props;
    const [data, setData] = useState(value);

    const onValueChange = (v) => {
        setData(v);
        setValue(settingName, v);
    };

    return (
        <FilesUploader isSingle data={{ value: data, setValue: onValueChange }} />
    );
};


export default UploadFileInput;